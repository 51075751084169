/*------------------------------------*\
    #TEXT
\*------------------------------------*/

// Paragraph base styles
p {
  margin-bottom: $spacing;
  font-family: $font-family-primary;
  font-weight: 400;
  font-size: $font-size-med;
  overflow-wrap: break-word;

  @media all and (max-width: $bp-large) {
    text-align: center;
  }
}

a {
  color: $color-action-green-500;
  font-weight: $font-weight-bold;
}

button,
span {
  font-family: $font-family-primary;
}

// Blockquote base styles
blockquote {
  font-style: italic;
  border-left: 1px solid $color-gray-500;
  color: $color-gray-500;
  padding-left: 1rem;
  margin-bottom: $spacing;
}

// Horizontal rule base styles
hr {
  border: 0;
  height: 4px;
  background: $color-gray-200;
  margin: 0.5rem 0 2rem;
}

// Code base styles
code,
samp,
pre {
  font-family: $font-family-mono;
}

code {
  display: inline-block;
  background: $color-gray-500;
  color: white;
  border: 1px solid $color-gray-200;
  padding: 0.2rem 0.5rem;
  line-height: 1.2;
  font-size: 0.85rem;
}

// Preformatted text base styles
pre {
  background: $color-gray-100;
  border: 1px solid $color-gray-200;
  font-size: $font-size-med;
  padding: 1rem;
  overflow-x: auto;

  // Remove border from code within preformatted text block
  code {
    border: 0;
    font-size: $font-size-med;
  }
}

h1,
.h1 {
  font-family: $font-family-secondary;
  font-weight: 800;
  color: $color-gray-500;
  font-size: $font-size-xl;
}

h2,
.h2 {
  font-family: $font-family-secondary;
  font-weight: 800;
  color: $color-gray-500;
  font-size: $font-size-lg;
}

h3,
.h3 {
  font-family: $font-family-secondary;
  font-weight: 700;
  color: $color-gray-500;
  font-size: $font-size-med;
  text-transform: uppercase;
}

.emphasis {
  font-weight: 700;
  color: $color-gray-500;
}

.c-stats-widget {
  max-width: 54rem;
  padding: 0 3rem;

  &__title {
    font-weight: $font-weight-bold;
    font-family: $font-family-primary;
    font-size: $font-size-med;
  }
  &__stat {
    font-weight: $font-weight-bold;
    font-family: $font-family-primary;
    font-size: $font-size-xl;
  }
  &__section {
    display: flex;
    align-items: center;
  }
  &__label {
    text-transform: uppercase;
    font-size: $font-size-sm;
    color: $color-report-label;
  }
  &__subtitle {
    font-weight: $font-weight-normal;
    font-family: $font-family-primary;
    font-size: $font-size-med;
    padding: 0 0 0.9rem 1rem;
  }
  &__content {
    font-weight: $font-weight-normal;
    font-family: $font-family-primary;
    font-size: $font-size-med;
    margin-top: 0.5rem;
  }
  &__key {
  }
  &__value {
  }
}

.c-bar-widget {
  max-width: 54rem;
  width: 54rem;
  padding: 0 3rem;
  position: relative;
  background-color: $color-report-bg;
  border-radius: 24px;
  border-bottom: 20px solid transparent;
  max-height: 54rem;
  margin: 4rem 2rem 0 2rem;
  overflow-y: hidden;

  &__header {
    display: flex;
  }

  &__group {
    overflow-y: auto;
    max-height: 38rem;
  }

  &__title {
    font-weight: $font-weight-bold;
    font-family: $font-family-primary;
    font-size: $font-size-med;
    margin-top: 3rem;
  }
  &__subtitle {
    font-weight: $font-weight-thin;
    font-family: $font-family-primary;
    font-size: $font-size-med;
    margin-bottom: 3rem;
  }
  &__stat {
    color: $color-report-stat-text;
    font-size: 10rem;
    margin: -3rem 0 0 0;
  }
  &__bar {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    height: 5.5rem;
    background: $color-report-bar-bg;
    border-radius: $border-radius;
  }
  &__lossbar {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    height: 5.5rem;
    background: #cc493d;
    border-radius: $border-radius;
  }
  &__label {
    z-index: 1;
    padding: 0 0 0 2rem;
    color: $color-report-bar-label;
  }
  &__value {
    z-index: 1;
    padding: 0 2rem 0 0;
    color: $color-report-content;
  }
  &__fill {
    position: absolute;
    width: 100%;
    height: 5.5rem;
    border-radius: $border-radius;
    background-color: $color-report-bar-fill;
    z-index: 0;
  }
  &__profitfill {
    position: absolute;
    width: 100%;
    height: 5.5rem;
    border-radius: $border-radius;
    background-color: #4cbf4c;
    z-index: 0;
  }
  &__empty-text {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  &--empty {
    .c-bar-widget__value,
    .c-bar-widget__label {
      display: none;
    }
    .c-bar-widget__bar {
      opacity: 0.2;
    }
  }
}

.c-reports {
  max-width: 180rem;
}

.c-box {
  width: 64rem;
  margin: 4rem 2rem 0rem 4rem;
  padding: 3rem 3rem 0rem 3rem;
  background-color: $color-gray-100;
  border-radius: 24px;
}

.c-stats-widget__stat {
  justify-content: flex-end;
}

.c-stats-widget__subtitle {
  margin-right: 10rem;
}

.c-line-widget {
  padding: 0 3rem 3rem 3rem;
  margin: 3rem 4rem 4rem 4rem;
  position: relative;
  background-color: $color-report-bg;
  border-radius: 24px;
  max-width: 100%;
  min-width: 110rem;

  &__title {
    font-weight: $font-weight-bold;
    font-family: $font-family-primary;
    font-size: $font-size-med;
    padding-top: 4rem;
  }
}

// NEED svg box around d3 line chart to show chart
.c-svg-box {
  height: 75rem;
  margin-left: 2rem;
  margin-bottom: 2rem;
}

@charset "UTF-8";
* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  margin: 0px;
}

html > body {
  padding: 0 !important;
}

input {
  background-color: transparent;
}

button {
  cursor: pointer;
}

a {
  cursor: pointer;
  text-decoration: none;
}

svg {
  overflow: visible;
}

/*------------------------------------*\
    #MIXINS
\*------------------------------------*/
/*------------------------------------*\
    #SPACING
\*------------------------------------*/
.u-margin-auto {
  margin: auto;
}

.u-margin-bottom-none {
  margin-bottom: 0 !important;
}

.u-margin-bottom-small {
  margin-bottom: 0.5rem !important;
}

.u-margin-bottom-med {
  margin-bottom: 1.5rem !important;
}

.u-margin-bottom {
  margin-bottom: 1rem !important;
}

.u-margin-bottom-three {
  margin-bottom: 3rem !important;
}

.u-margin-bottom-large {
  margin-bottom: 2rem !important;
}

.u-margin-bottom-xlarge {
  margin-bottom: 4rem !important;
}

.u-margin-large {
  margin: 2rem !important;
}

.u-margin-xlarge {
  margin: 4rem !important;
}

.u-margin-xxlarge {
  margin: 5rem !important;
}

.u-margin-three {
  margin: 3rem !important;
}

.u-margin-bottom-large-to-xxl {
  margin-bottom: 2rem !important;
}
@media all and (min-width: 60em) {
  .u-margin-bottom-large-to-xxl {
    margin-bottom: 8rem !important;
  }
}

.u-margin-top-none {
  margin-top: 0 !important;
}

.u-margin-top-small {
  margin-top: 0.5rem !important;
}

.u-margin-top {
  margin-top: 1rem !important;
}

.u-margin-top-med {
  margin-top: 1.5rem !important;
}

.u-margin-top-large {
  margin-top: 2rem !important;
}

.u-margin-top-xlarge {
  margin-top: 4rem !important;
}

.u-margin-top-xxlarge {
  margin-top: 5rem !important;
}

.u-margin-top-8 {
  margin-top: 8rem !important;
}

.u-margin-right-auto {
  margin-right: auto !important;
}

.u-margin-right-none {
  margin-right: 0 !important;
}

.u-margin-right-small {
  margin-right: 0.5rem !important;
}

.u-margin-right {
  margin-right: 1rem !important;
}

.u-margin-right-large {
  margin-right: 2rem !important;
}

.u-margin-right-xlarge {
  margin-right: 4rem !important;
}

.u-margin-left-none {
  margin-left: 0 !important;
}

.u-margin-left-small {
  margin-left: 0.5rem !important;
}

.u-margin-left {
  margin-left: 1rem !important;
}

.u-margin-left-large {
  margin-left: 2rem !important;
}

.u-margin-left-xlarge {
  margin-left: 4rem !important;
}

.u-margin-none {
  margin: 0 !important;
}

.u-margin {
  margin: 1rem !important;
}

.u-margin-center {
  margin: 0 auto !important;
}

.u-padding-none {
  padding: 0 !important;
}

.u-padding-small {
  padding: 0.5rem !important;
}

.u-padding {
  padding: 1rem !important;
}

.u-padding-large {
  padding: 2rem !important;
}

.u-padding-xlarge {
  padding: 4rem !important;
}

.u-padding-side {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.u-padding-top-none {
  padding-top: 0 !important;
}

.u-padding-top-small {
  padding-top: 0.5rem !important;
}

.u-padding-top {
  padding-top: 1rem !important;
}

.u-padding-top-large {
  padding-top: 2rem !important;
}

.u-padding-bottom-none {
  padding-bottom: 0 !important;
}

.u-padding-bottom-small {
  padding-bottom: 0.5rem !important;
}

.u-padding-bottom {
  padding-bottom: 1rem !important;
}

@media (max-width: 1200px) {
  .u-padding-bottom-large {
    padding-bottom: 20vh !important;
  }
}
@media (min-width: 1201px) {
  .u-padding-bottom-large {
    padding-bottom: 2rem !important;
  }
}

.u-padding-right-none {
  padding-right: 0 !important;
}

.u-padding-right-small {
  padding-right: 0.5rem !important;
}

.u-padding-right {
  padding-right: 1rem !important;
}

.u-padding-right-large {
  padding-right: 2rem !important;
}

.u-padding-left-none {
  padding-left: 0 !important;
}

.u-padding-left-small {
  padding-left: 0.5rem !important;
}

@media all and (min-width: 47em) {
  .u-padding-left-small-desktop {
    padding-left: 0.5rem !important;
  }
}

@media all and (min-width: 47em) {
  .u-padding-right-small-desktop {
    padding-right: 0.5rem !important;
  }
}

.u-padding-left {
  padding-left: 1rem !important;
}

.u-padding-left-large {
  padding-left: 2rem !important;
}

.u-padding-left-10rem {
  padding-left: 10rem !important;
}

.u-gap-small {
  gap: 5rem;
}

.u-justify-space-around {
  justify-content: space-around !important;
}

.u-justify-space-between {
  justify-content: space-between !important;
}

.u-align-center {
  align-items: center;
}

.u-align-start {
  align-items: flex-start !important;
}

.u-input-side-by-side {
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem !important;
  width: 95%;
}

.u-width-50-percent {
  width: 50% !important;
}

.u-width-80-percent {
  width: 80% !important;
}

.u-width-100-percent {
  width: 100% !important;
}

.u-text-center {
  text-align: center !important;
}

.u-text-left {
  text-align: left !important;
}

.u-font-size-small {
  font-size: 1.5rem !important;
}

.u-font-weight-400 {
  font-weight: 400;
}

.u-font-weight-500 {
  font-weight: 500;
}

.u-font-weight-bold {
  font-weight: bold;
}

.u-font-mono {
  font-family: "Roboto Mono", monospace;
}

.u-font-sec {
  font-family: "Merriweather";
}

.u-text-transform-initial {
  text-transform: initial !important;
}

.u-text-success {
  color: #41b300;
}

.u-text-error {
  color: #e50000;
}

.u-text-success {
  color: #41b300;
}

/*------------------------------------*\
    #CURSOR
\*------------------------------------*/
.u-cursor-pointer {
  cursor: pointer;
}

.u-cursor-none {
  cursor: default !important;
}

.u-cursor-help {
  cursor: help;
}

[data-reach-dialog-overlay] {
  z-index: 9999;
}

:root {
  --reach-tooltip: 1;
}

[data-reach-tooltip] {
  z-index: 1;
  pointer-events: none;
  position: absolute;
  padding: 0.25em 0.5em;
  box-shadow: 2px 2px 10px hsla(0deg, 0%, 0%, 0.1);
  white-space: nowrap;
  font-size: 85%;
  background: #403d38;
  color: #444;
  border: solid 1px #ccc;
  border-radius: 1rem;
}

.rbt {
  color: #8ca608;
  z-index: inherit;
}
.rbt:after {
  content: "";
  text-align: end;
  position: absolute;
  top: 2.5rem;
  right: 2rem;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
}
.rbt .rbt-input-main::placeholder {
  color: #fff;
}

.typeahead-secondary .rbt-input {
  background-color: transparent;
  color: #66645e;
  font-weight: normal;
}
.typeahead-secondary .rbt-input:hover, .typeahead-secondary .rbt-input:focus {
  background-color: transparent;
  color: #6c800d;
}
.typeahead-secondary .rbt-input:disabled {
  background-color: transparent;
  color: #8ca608;
  opacity: 1;
  border-color: #e3e1dc;
  font-weight: bold;
}
.typeahead-secondary .rbt:after {
  display: none;
}

.rbt-input {
  border: 2px solid #8ca608;
  background-color: #8ca608;
  color: #fff;
  padding: 1.5rem 2rem;
  display: inline-block;
  text-align: left;
  white-space: nowrap;
  font-weight: bold;
  appearance: none;
  border: none;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  line-height: inherit;
  transition: all 0.4s ease;
  transition: all 0.15s ease-out;
  border-radius: 0.5em;
}
.rbt-input:hover, .rbt-input:focus {
  transition: all 0.4s ease;
  color: #fff;
  background-color: #6c800d;
  border-color: #6c800d;
}
.rbt-input:disabled, .rbt-input[disabled] {
  background-color: #8ca608;
  cursor: text;
  opacity: 0.4;
  color: #fff;
  border-color: #8ca608;
}

.rbt-input-main {
  border: 1px solid #8ca608;
}
.rbt-input-main::placeholder {
  color: #8ca608;
}

.rbt-menu {
  position: relative;
  top: 100%;
  border: 1px solid #ccc;
  border-radius: 1rem;
  padding-left: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin: 0.75rem 0 0 0;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.3019607843);
  background-color: #fff;
  list-style-type: none;
  display: flex !important;
  flex-direction: column;
  z-index: 99999;
}
.rbt-menu a {
  display: block;
}

.rbt-menu li {
  padding-left: 1rem;
  border-left: 3px solid transparent;
  width: 100%;
}
.rbt-menu li:hover {
  border-color: #8ca608;
}
.rbt-menu li > a {
  display: block;
  width: 100%;
}

.rbt-menu > .dropdown-item {
  color: #8ca608;
  padding: 0.5rem;
}
.rbt-menu > .dropdown-item::before {
  display: inline-block;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 2rem;
  content: "●";
  color: transparent;
}
.rbt-menu > .dropdown-item.is-active {
  color: #8ca608;
}
.rbt-menu > .dropdown-item.is-active > a {
  color: #8ca608;
}
.rbt-menu > .dropdown-item.is-active::before {
  color: #8ca608;
}
.rbt-menu > .dropdown-item:hover {
  color: #8ca608;
}
.rbt-menu > .dropdown-item:hover::before {
  color: #8ca608;
}

.rbt-menu .dropdown-header {
  cursor: default;
  background: #fff;
}
.rbt-menu .dropdown-header:hover {
  border-color: transparent !important;
}

.rbt-aux {
  right: 8rem;
}
.rbt-aux .rbt-close {
  margin-top: 0;
  background-color: transparent;
  border: 1px solid #8ca608;
  border-radius: 100px;
  margin-right: 3rem;
  font-weight: 100;
  overflow: hidden;
  display: flex;
  height: 2.5rem;
  min-width: 2.5rem;
  color: #8ca608;
}
.rbt-aux .rbt-close .rbt-close-content {
  font-size: 2.4rem;
  transform: translate(-0.25rem, -0.6rem);
}

.rbt-input-hint {
  color: transparent !important;
  overflow: hidden;
  width: 100%;
}

.rbt-input-multi {
  max-width: 100%;
}
.rbt-input-multi .rbt-input-hint-container::after {
  display: none;
}
.rbt-input-multi .rbt-input-wrapper {
  margin: 1px 45px 1px 0px;
  max-height: 8rem;
  overflow: auto;
  background-color: white;
  border-radius: 1rem;
  padding: 0 0 0 7px;
}
.rbt-input-multi .rbt-input-wrapper::-webkit-scrollbar {
  display: none;
}

.rbt-input-multi.focus {
  box-shadow: none !important;
  border-color: none !important;
}

.rbt-input-multi.form-control[disabled] {
  background-color: transparent;
}

.rbt-token {
  background-color: #8ca608;
  color: #fff;
  border-radius: 100px;
  padding: 0.5rem;
  margin-right: 0.5rem;
  font-weight: bold;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rbt-token:hover {
  background-color: #8ca608;
}
.rbt-token .rbt-token-remove-button {
  bottom: 0;
  background-color: transparent;
  border: none;
  color: inherit;
  font-size: inherit;
  font-weight: normal;
  opacity: 1;
  outline: none;
  padding: 3px 7px;
  position: relative;
  right: 0;
  left: 1px;
  text-shadow: none;
  top: -1px;
  height: 3px;
}

.rbt-token-removeable {
  cursor: pointer;
}

.has-aux .form-control {
  color: #8ca608;
  font-weight: bold;
}

.typeahead-link {
  background-color: transparent;
  color: #8ca608;
  opacity: 1;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid #e3e1dc;
  padding: 1.5rem 2rem;
  display: inline-block;
  text-align: left;
  white-space: nowrap;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  line-height: initial;
  transition: all 0.15s ease-out;
  border-radius: 0.5em;
  height: 5.5rem;
}
.typeahead-link--no-border {
  border: none;
}

/*------------------------------------*\
    #LAYOUT
\*------------------------------------*/
.l-container, .l-container-table, .l-container-center, .l-container-lg, .l-container-med-plus, .l-container-med, .l-container-sm-plus, .l-container-sm, .l-form-column, .l-container-xs-plus, .l-container-xs {
  margin: 0 auto;
  overflow: auto;
}
@media all and (min-width: 60em) {
  .l-container, .l-container-table, .l-container-center, .l-container-lg, .l-container-med-plus, .l-container-med, .l-container-sm-plus, .l-container-sm, .l-form-column, .l-container-xs-plus, .l-container-xs {
    padding: 0;
  }
}
.l-container--left {
  margin-left: 0;
}
.l-container--right {
  margin-right: 0;
}

.l-container-xs {
  max-width: 20rem;
}

.l-container-xs-plus {
  max-width: 30rem;
}

.l-container-sm, .l-form-column {
  max-width: 40rem;
}

.l-container-sm.with-min-width, .with-min-width.l-form-column {
  min-width: 38rem;
}

.l-container-sm-plus {
  max-width: 60rem;
}

.l-container-med {
  max-width: 80rem;
}

.l-container-med-plus {
  max-width: 100rem;
}

.l-container-lg {
  max-width: 120rem;
}

.d-none {
  display: none !important;
}

.l-flex-column {
  display: flex;
  flex-direction: column;
}

.l-container-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.l-container-flex-box {
  flex: 0 1 350px;
  margin: 10px;
}

.l-container-table {
  overflow-x: hidden;
}

.l-flex {
  display: flex;
  flex-flow: wrap;
  gap: 1rem 0;
}

.l-inline-flex {
  display: inline-flex;
}

.l-flex-between {
  display: flex;
  justify-content: space-between;
}

.l-flex-start {
  display: flex;
  justify-content: flex-start;
}

.l-flex-end {
  display: flex;
  justify-content: flex-end;
}

.l-flex-auto {
  flex: 1 1 auto;
}

.l-flex-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.l-flex-gap {
  display: inline-flex;
  flex-wrap: nowrap;
  gap: 12px;
}

.l-flex-center {
  display: flex;
  justify-content: center;
}

.l-align-start {
  display: flex;
  align-items: flex-start !important;
}

.l-align-end {
  display: flex;
  align-items: flex-end !important;
}

.l-flex-wrap-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.l-flex-align-center {
  display: flex;
  align-items: center;
}
@media all and (max-width: 549px) {
  .l-flex-align-center--mobile {
    flex-direction: column;
    align-items: center;
  }
}

.l-view-layout--sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
}
@media all and (min-width: 60em) {
  .l-view-layout--sidebar {
    margin-left: 40rem;
  }
}

.l-full-height {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.l-full-width {
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.l-view-layout__pricing-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 3rem;
}
@media all and (max-width: 47em) {
  .l-view-layout__pricing-content {
    display: block;
  }
}

.l-view-layout__details-content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 4rem;
}
@media all and (max-width: 47em) {
  .l-view-layout__details-content {
    display: block;
  }
}

.l-view-layout__details-content-three-cols {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 4rem;
}
@media all and (max-width: 47em) {
  .l-view-layout__details-content-three-cols {
    display: block;
  }
}

.l-view-layout__pricing-table {
  display: block;
  place-items: center;
  overflow: auto;
}

.l-view-layout__pricing-table-offset {
  padding-right: 31rem;
}

.l-view-layout__main {
  position: relative;
  padding: 0 4rem;
  width: 100%;
  min-height: 100vh;
  overflow-x: auto;
}
@media all and (min-width: 47em) {
  .l-view-layout--two-column .l-view-layout__main {
    width: 70%;
    padding-right: 1rem;
  }
}

.l-grid-row-uneven {
  display: grid;
  grid-template-columns: minmax(100px, 25%) 1fr;
  column-gap: 2rem;
}
.l-grid-row-uneven--reverse {
  grid-template-columns: 1fr minmax(100px, 25%);
}
@media all and (max-width: 47em) {
  .l-grid-row-uneven {
    display: block;
  }
}

.l-grid-row-report {
  display: grid;
  grid-template-columns: minmax(100px, 50%) 1fr;
  column-gap: 2rem;
}

.l-grid-row-stats-trends {
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}

.l-view-layout__sidebar {
  width: 100%;
}
@media all and (min-width: 47em) {
  .l-view-layout__sidebar {
    width: 60rem;
    height: 100%;
  }
}

.l-cards-grid {
  height: auto;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
}

.l-form-column {
  width: 100%;
}

.l-view-layout__promo {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem;
}
.l-view-layout__promo .promo-image {
  margin-top: 4rem;
  height: auto;
  max-width: 100%;
  cursor: zoom-in;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.l-view-layout--user-profile {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 25rem 20rem;
  gap: 7rem;
}
@media all and (max-width: 1455px) {
  .l-view-layout--user-profile {
    margin: 5rem auto;
    width: 40rem;
  }
}
.l-view-layout--user-profile .user-profile-form-column {
  flex: 1;
}
@media all and (max-width: 1455px) {
  .l-view-layout--user-profile .user-profile-form-column {
    flex: 0 0 100%;
  }
}

.l-view-layout--subheader {
  display: flex;
  justify-content: space-between;
  background-color: #edebe8;
  padding: 1.5rem 5rem;
  align-items: center;
}
.l-view-layout--subheader h1 {
  margin: 0;
}

.l-view-layout--subheader--active {
  background-color: #8ca608;
}
.l-view-layout--subheader--active h1 {
  color: #fff;
}

.l-view-layout--details-col {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.l-header--details {
  background-color: #edebe8;
  border-radius: 1.5rem;
  padding-left: 3rem;
  padding-right: 0.75rem;
  margin-bottom: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7rem;
}

.l-view-layout--details-form {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 4rem;
  align-items: center;
  margin-bottom: 1.5rem;
  min-height: 5.5rem;
}
.l-view-layout--details-form label {
  text-align: right;
}
.l-view-layout--details-form span {
  padding-left: 2rem;
}

.l-view-layout--details-form--offspring {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 4rem;
  margin-bottom: 1.5rem;
  min-height: 5.5rem;
}
.l-view-layout--details-form--offspring label {
  text-align: right;
}

.l-date-display {
  background-image: url("styles/assets/icons/calendar-disabled.svg");
  background-repeat: no-repeat;
  background-position: 0 20%;
  padding-left: 3rem !important;
}

.l-log-count {
  background-color: #edebe8;
  border-radius: 1rem;
  font-weight: bold;
  padding: 1.5rem;
  min-width: 9rem;
}

.l-typeahead-table {
  height: 35rem;
  overflow: auto;
}
.l-typeahead-table::-webkit-scrollbar {
  display: none;
}

.l-filter-drawer-form {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  transition: all 0.4s ease;
  max-height: 100rem;
}

.l-filter-drawer {
  margin: auto;
  padding-top: 4rem;
  padding-bottom: 6rem;
  max-width: 120rem;
}
.l-filter-drawer__fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 4rem;
}
.l-filter-drawer__buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 4rem;
}

.l-view-layout--filter-field {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 4rem;
  align-items: center;
  margin-bottom: 3rem;
  min-height: 5.5rem;
}
.l-view-layout--filter-field label {
  text-align: right;
}
.l-view-layout--filter-field span {
  margin-left: 1rem;
  margin-right: 1rem;
}

.l-view-hidden {
  max-height: 0;
  opacity: 0;
  visibility: hidden;
}

.l-view-layout--offspring {
  display: grid;
  grid-template-columns: 5fr 1fr;
}

.checkbox-custom {
  opacity: 0;
  position: absolute;
}

.checkbox-custom,
.checkbox-custom-label {
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
  cursor: pointer;
}

.checkbox-custom-label {
  position: relative;
}

.checkbox-custom + .checkbox-custom-label:before {
  content: "";
  background: #fff;
  border: 2px solid #66645e;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 4px;
  text-align: center;
  transition-duration: 0.3s;
}

.checkbox-custom:checked + .checkbox-custom-label:before {
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  font-size: 18px;
  background: #8ca608;
  border: 2px solid #8ca608;
  border-radius: 4px;
  vertical-align: middle;
  color: #fff;
  transition-duration: 0.3s;
  line-height: 1.4;
}

.minusbox {
  display: inline-flex;
  cursor: pointer;
  position: relative;
}

.minusbox > span {
  color: #8ca608;
}

.minusbox > input {
  height: 25px;
  width: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 2px solid #66645e;
  border-radius: 4px;
  outline: none;
  transition-duration: 0.3s;
  background-color: #fff;
  cursor: pointer;
}

.minusbox > input:checked {
  border: 1px solid #8ca608;
  background-color: #8ca608;
}

.minusbox > input:checked + span::before {
  content: "―";
  font-size: 22px;
  display: block;
  text-align: center;
  color: #fff;
  position: absolute;
  left: 0.7rem;
  top: -0.4rem;
}

.minusbox > input:active {
  border: 2px solid #8ca608;
}

.c-radio-button {
  display: inline-flex;
  cursor: pointer;
  position: relative;
}

.c-radio-button > span {
  color: #66645e;
}

.c-radio-button > input {
  height: 3rem;
  width: 3rem !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 2px solid #66645e;
  border-radius: 100px;
  outline: none;
  transition-duration: 0.3s;
  cursor: pointer;
  padding: 0;
  margin-right: 1rem;
}

.c-radio-button > input:checked {
  border: 2px solid #8ca608;
  background-color: #8ca608;
}

.c-radio-button > input:checked::before {
  content: "";
  width: 1rem;
  height: 1rem;
  border-radius: 50px;
  display: block;
  text-align: center;
  color: #fff;
  position: absolute;
  left: 1rem;
  top: 1rem;
  background: #fff;
}

.c-radio-button > input:active {
  border: 2px solid #8ca608;
}

input:disabled + label {
  opacity: 0.3;
  cursor: not-allowed;
}

svg {
  height: 2.1rem;
  width: 2.5rem;
}

.spinner, .spinner-blur {
  position: relative;
  display: flex;
  justify-content: center;
  height: 80rem;
  width: 100%;
  top: 4rem;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
}
.spinner > *, .spinner-blur > * {
  height: 20%;
  width: 20%;
  fill: #8ca608;
}

.spinner-blur {
  position: absolute !important;
}

.spinning {
  animation: spin 1.1s infinite;
  display: inline-block;
}

.loading-small {
  margin: auto;
}
.loading-small img {
  width: 2em;
  height: 2em;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
/*------------------------------------*\
    #BODY
\*------------------------------------*/
html {
  min-height: 100vh;
  font-size: 8px;
}

/**
 * Body base styles – Set the body element's height to at least 100% of the viewport.
 *    This is used to achieve a sticky footer
 */
body {
  min-height: 100vh; /* 1 */
  font-family: "Roboto", sans-serif;
  font-size: 2rem;
  line-height: 1.6;
  -webkit-text-size-adjust: 100%;
  background-color: #fff;
  color: #66645e;
}

/*------------------------------------*\
    #HEADINGS
\*------------------------------------*/
h1,
h2,
h3,
h4 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin: 1.5rem 0;
}

/*------------------------------------*\
    #TEXT
\*------------------------------------*/
p {
  margin-bottom: 1rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 2rem;
  overflow-wrap: break-word;
}
@media all and (max-width: 60em) {
  p {
    text-align: center;
  }
}

a {
  color: #8ca608;
  font-weight: bold;
}

button,
span {
  font-family: "Roboto", sans-serif;
}

blockquote {
  font-style: italic;
  border-left: 1px solid #66645e;
  color: #66645e;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

hr {
  border: 0;
  height: 4px;
  background: #e3e1dc;
  margin: 0.5rem 0 2rem;
}

code,
samp,
pre {
  font-family: "Roboto Mono", monospace;
}

code {
  display: inline-block;
  background: #66645e;
  color: white;
  border: 1px solid #e3e1dc;
  padding: 0.2rem 0.5rem;
  line-height: 1.2;
  font-size: 0.85rem;
}

pre {
  background: #edebe8;
  border: 1px solid #e3e1dc;
  font-size: 2rem;
  padding: 1rem;
  overflow-x: auto;
}
pre code {
  border: 0;
  font-size: 2rem;
}

h1,
.h1 {
  font-family: "Merriweather";
  font-weight: 800;
  color: #66645e;
  font-size: 4rem;
}

h2,
.h2 {
  font-family: "Merriweather";
  font-weight: 800;
  color: #66645e;
  font-size: 3rem;
}

h3,
.h3 {
  font-family: "Merriweather";
  font-weight: 700;
  color: #66645e;
  font-size: 2rem;
  text-transform: uppercase;
}

.emphasis {
  font-weight: 700;
  color: #66645e;
}

/*------------------------------------*\
    #FORMS
\*------------------------------------*/
/**
 * 1) Form element base styles
 */
::-webkit-input-placeholder {
  color: #66645e;
}

::-moz-placeholder {
  color: #66645e;
}

:-ms-input-placeholder {
  color: #66645e;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

legend {
  margin-bottom: 0.25rem;
}

label {
  display: block;
  padding-bottom: 0.25rem;
  color: #66645e;
}

button,
input,
select,
textarea {
  font-size: 2rem;
  margin: 0;
  color: #66645e;
}

input {
  width: 100%;
  height: 5.5rem;
  border: 1px solid #8ca608;
  border-radius: 1rem;
  padding: 0.5rem 2rem;
  background: #fff;
}
input:focus {
  border-color: #8ca608;
  outline: #8ca608;
  box-shadow: 0 0 0 0.05em #e0f869, 0 0 0.15em 0.1em #e0f869;
}
input:disabled {
  border-color: #e3e1dc;
  background: #fff;
  color: #66645e;
  cursor: text;
}

input[type=text],
input[type=tel],
input[type=password],
input[type=search],
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=url],
input[type=number],
textarea {
  -webkit-appearance: none;
}

input[type=checkbox],
input[type=radio] {
  width: auto;
}

input[type=search] {
  -webkit-appearance: none;
}

input[type=password] {
  -webkit-text-security: square;
}

textarea {
  width: 100%;
  border: 1px solid #8ca608;
  border-radius: 1rem;
  padding: 2rem;
  background: #fff;
  resize: none;
}
textarea:focus {
  border-color: #8ca608;
  outline: #8ca608;
  box-shadow: 0 0 0 0.05em #e0f869, 0 0 0.15em 0.1em #e0f869;
}
textarea:disabled {
  border-color: #e3e1dc;
  background: #fff;
  color: #66645e;
  cursor: text;
}

select {
  border: 2px solid #8ca608;
  background-color: #8ca608;
  color: #fff;
  padding: 1.5rem 0 1.5rem 2rem;
  display: inline-block;
  text-align: left;
  white-space: nowrap;
  font-weight: bold;
  appearance: none;
  border: none;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  line-height: inherit;
  transition: all 0.4s ease;
  transition: all 0.15s ease-out;
  border-radius: 0.5em;
  outline: none;
}
select:hover, select:focus {
  transition: all 0.4s ease;
  color: #fff;
  background-color: #6c800d;
  border-color: #6c800d;
}
select:disabled, select[disabled] {
  background-color: #8ca608;
  cursor: text;
  border-color: #8ca608;
}
select:after {
  content: "";
  text-align: end;
  position: absolute;
  top: 2.5rem;
  right: 2rem;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
}
select::-ms-expand {
  display: none;
}

form button {
  display: inline-flex;
  align-items: center;
}
form button svg {
  vertical-align: middle;
  fill: #fff;
  margin-right: 5px;
}

a.c-btn, a.dd-menu__subitem, a.dd-button, a.c-btn-filter, a.c-btn-bare, a.dd-button-bare, a.dd-button-no-styles, a.c-btn-bare-tab, a.c-btn-alt, a.c-btn__done, a.c-btn-tab, a.c-btn-tab__skinny, a.c-btn__edit, a.c-btn__login {
  display: flex;
}

a.c-btn-full {
  display: flex;
  width: 100%;
}

.c-btn, .dd-menu__subitem, .dd-button, .c-btn-filter, .c-btn-bare, .dd-button-bare, .dd-button-no-styles, .c-btn-bare-tab, .c-btn-alt, .c-btn__done, .c-btn-tab, .c-btn-tab__skinny, .c-btn__edit, .c-btn__login {
  display: inline-block;
  border: 2px solid #8ca608;
  background: #8ca608;
  transition: all 0.4s ease;
  color: #fff;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  min-width: 14.25rem;
  font-weight: bold;
  text-transform: capitalize;
  text-align: center;
  transition: all 0.15s ease-out;
  border-radius: 1rem;
  white-space: normal;
  cursor: pointer;
}
.c-btn__login {
  width: 35rem;
}
.c-btn__edit {
  min-width: 10.5rem;
}
.c-btn__label {
  line-height: normal;
}
.c-btn__done {
  min-width: 10.5rem;
}
.c-btn__inner {
  display: flex;
  font-size: 2rem;
  align-items: center;
  justify-content: space-evenly;
  padding-right: 1rem;
  padding-left: 1rem;
}
.c-btn__inner--mid-right {
  justify-content: space-between;
}
.c-btn__inner--mid-right span {
  width: 100%;
  text-align: center;
}
.c-btn__underlined {
  display: flex;
  font-size: 2rem;
  align-items: center;
  justify-content: center;
  text-decoration: underline;
}
.c-btn__icon {
  height: 2.5rem;
  fill: #fff;
  transition: fill 0.15s ease-out;
}
.c-btn:hover, .dd-menu__subitem:hover, .dd-button:hover, .c-btn-filter:hover, .c-btn-bare:hover, .dd-button-bare:hover, .dd-button-no-styles:hover, .c-btn-bare-tab:hover, .c-btn-alt:hover, .c-btn__done:hover, .c-btn-tab:hover, .c-btn-tab__skinny:hover, .c-btn__login:hover, .c-btn__edit:hover, .c-btn:focus, .dd-menu__subitem:focus, .dd-button:focus, .c-btn-filter:focus, .c-btn-bare:focus, .dd-button-bare:focus, .dd-button-no-styles:focus, .c-btn-bare-tab:focus, .c-btn-alt:focus, .c-btn__done:focus, .c-btn-tab:focus, .c-btn-tab__skinny:focus, .c-btn__login:focus, .c-btn__edit:focus {
  transition: all 0.4s ease;
  color: #fff;
  background-color: #6c800d;
  border-color: #6c800d;
}
.c-btn:disabled, .dd-menu__subitem:disabled, .dd-button:disabled, .c-btn-filter:disabled, .c-btn-bare:disabled, .dd-button-bare:disabled, .dd-button-no-styles:disabled, .c-btn-bare-tab:disabled, .c-btn-alt:disabled, .c-btn__done:disabled, .c-btn-tab:disabled, .c-btn-tab__skinny:disabled, .c-btn__login:disabled, .c-btn__edit:disabled, .c-btn[disabled], [disabled].dd-menu__subitem, [disabled].dd-button, [disabled].c-btn-filter, [disabled].c-btn-bare, [disabled].dd-button-bare, [disabled].dd-button-no-styles, [disabled].c-btn-bare-tab, [disabled].c-btn-alt, [disabled].c-btn__done, [disabled].c-btn-tab, [disabled].c-btn-tab__skinny, [disabled].c-btn__login, [disabled].c-btn__edit {
  background-color: #8ca608;
  cursor: not-allowed;
  opacity: 0.4;
  border-color: #8ca608;
}

.c-btn-tab, .c-btn-tab__skinny {
  min-width: 5.8rem;
  text-align: center;
}
.c-btn-tab__skinny {
  height: 2rem;
  padding-top: 0;
  padding-bottom: 0;
}

.c-btn-wide, .c-btn-alt-wide {
  display: inline-block;
  border: 2px solid #8ca608;
  background: #8ca608;
  transition: all 0.4s ease;
  color: #fff;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  width: 42.25rem;
  font-weight: bold;
  text-transform: capitalize;
  text-align: center;
  transition: all 0.15s ease-out;
  border-radius: 0.5em;
  white-space: normal;
  cursor: pointer;
}
.c-btn-wide__inner {
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-btn-wide__icon {
  height: 1.75rem;
  fill: #fff;
  transition: fill 0.15s ease-out;
  padding-right: 1rem;
}
.c-btn-wide:hover, .c-btn-alt-wide:hover, .c-btn-wide:focus, .c-btn-alt-wide:focus {
  transition: all 0.4s ease;
  color: #fff;
  background-color: #6c800d;
  border-color: #6c800d;
}
.c-btn-wide:disabled, .c-btn-alt-wide:disabled, .c-btn-wide[disabled], [disabled].c-btn-alt-wide {
  background-color: #8ca608;
  cursor: not-allowed;
  opacity: 0.3;
  border-color: #8ca608;
}

.c-btn-icon-bare {
  border-color: transparent;
  color: #8ca608;
  background: transparent;
  margin: 1rem;
  font-size: 2.5rem;
}
.c-btn-icon-bare:hover, .c-btn-icon-bare:focus, .c-btn-icon-bare__active {
  cursor: pointer;
  color: #6c800d;
  border-color: transparent;
  background: transparent;
}

.c-btn-alt, .c-btn__done {
  color: #8ca608;
  border-color: #8ca608;
  background: #fff;
}
.c-btn-alt .c-btn-icon__chevron-alt, .c-btn__done .c-btn-icon__chevron-alt {
  display: flex;
  font-size: 2.5rem;
  align-items: center;
  justify-content: space-evenly;
  padding-right: 1.5rem;
}
.c-btn-alt:hover, .c-btn__done:hover, .c-btn-alt:focus, .c-btn__done:focus {
  color: #6c800d;
  background-color: #fff;
  border-color: #8ca608;
}

.c-btn-alt-wide {
  color: #8ca608;
  border-color: #8ca608;
  background: #fff;
}
.c-btn-alt-wide:hover, .c-btn-alt-wide:focus {
  background-color: #6c800d;
}

.c-btn-icon, .c-btn-idscan, .c-btn-icon-mini, .c-btn-icon-static, .c-btn-icon-alt {
  display: inline-block;
  border: 2px solid #8ca608;
  background: #8ca608;
  transition: all 0.4s ease;
  color: #fff;
  font-weight: bold;
  text-transform: capitalize;
  text-align: center;
  transition: all 0.15s ease-out;
  border-radius: 0.5em;
  white-space: normal;
  cursor: pointer;
  width: 5.5rem;
  height: 5.5rem;
}
.c-btn-icon__inner {
  display: flex;
  font-size: 2.5rem;
  align-items: center;
  justify-content: center;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.c-btn-icon__chevron {
  display: flex;
  font-size: 2.5rem;
  align-items: center;
  justify-content: space-evenly;
  padding-right: 1.5rem;
}
.c-btn-icon__right {
  display: flex;
  font-size: 2.5rem;
  align-items: center;
}
.c-btn-icon__text {
  font-size: 2rem;
  padding-left: 1rem;
}
.c-btn-icon:hover, .c-btn-idscan:hover, .c-btn-icon-mini:hover, .c-btn-icon-static:hover, .c-btn-icon-alt:hover {
  background-color: #6c800d;
  border-color: #6c800d;
}
.c-btn-icon:active, .c-btn-idscan:active, .c-btn-icon-mini:active, .c-btn-icon-static:active, .c-btn-icon-alt:active {
  background-color: #6c800d;
  border-color: #6c800d;
}

.c-btn-icon-alt {
  background-color: #fff;
  border-color: transparent;
  color: #8ca608;
}
.c-btn-icon-alt:hover {
  background-color: #8ca608;
  border-color: #8ca608;
  color: #fff;
}

.c-btn-icon-static {
  background-color: #fff;
  border-color: transparent;
  color: #8ca608;
}
.c-btn-icon-static:hover {
  background-color: #fff;
  border-color: transparent;
  color: #8ca608;
  cursor: default;
}

.c-btn-icon-mini {
  width: 4rem;
  height: 4rem;
}
.c-btn-icon-mini__inner {
  font-size: 2.5rem;
}

.c-btn-idscan {
  width: 30.375rem;
  height: 19.125rem;
}
.c-btn-idscan__icon {
  display: flex;
  font-size: 4rem;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.5rem;
}
.c-btn-idscan__inner {
  display: flex;
  font-size: 2rem;
  align-items: center;
  justify-content: center;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.c-btn-idscan__corner-top {
  display: flex;
  font-size: 2rem;
  justify-content: space-between;
  padding: 0 0.4rem;
  transform: translate(0, -2.5rem);
}
.c-btn-idscan__corner-top .c-btn-left {
  transform: rotate(-45deg);
}
.c-btn-idscan__corner-top .c-btn-right {
  transform: rotate(45deg);
}
.c-btn-idscan__corner-bottom {
  display: flex;
  font-size: 2rem;
  justify-content: space-between;
  padding: 0 0.4rem;
  transform: translate(0, 2.5rem);
}
.c-btn-idscan__corner-bottom .c-btn-left {
  transform: rotate(45deg);
}
.c-btn-idscan__corner-bottom .c-btn-right {
  transform: rotate(-45deg);
}

.c-btn-bare, .dd-button-bare, .dd-button-no-styles, .c-btn-bare-tab {
  border-color: transparent;
  color: #8ca608;
  background: transparent;
}
.c-btn-bare:hover, .dd-button-bare:hover, .dd-button-no-styles:hover, .c-btn-bare-tab:hover, .c-btn-bare:focus, .dd-button-bare:focus, .dd-button-no-styles:focus, .c-btn-bare-tab:focus, .c-btn-bare__active {
  cursor: pointer;
  color: #6c800d;
  border-color: transparent;
  background: transparent;
}

.c-btn-bare-tab {
  min-width: 5.8rem;
}

.c-btn-filter {
  border: 1px solid #8ca608;
  border-radius: 100px;
  padding: 0.75rem;
  min-height: 4rem;
  height: 4rem;
  min-width: 5.75rem;
  border-color: #8ca608;
  color: #66645e;
  background: transparent;
  font-weight: initial;
  transition: all 0.15s ease-out;
  cursor: pointer;
}
.c-btn-filter .c-btn__inner {
  padding-right: 3rem;
  padding-left: 0;
  position: relative;
}
.c-btn-filter .c-btn__icon {
  position: absolute;
  font-size: 2.5rem;
  top: 0rem;
  right: 0rem;
  padding: 0 0 0 1rem;
}
.c-btn-filter:hover, .c-btn-filter:focus {
  background-color: #8ca608;
  color: #fff;
  border-color: #8ca608;
}

.c-btn-profile {
  background-color: #fff;
  color: #8ca608;
  border-radius: 100px;
  border: #fff;
  height: 6rem;
  width: 6rem;
}

.c-btn-dd-profile {
  margin: 10rem 2rem 2rem 2rem;
  background-color: #fff;
  color: #8ca608;
  border-radius: 100px;
  border: #fff;
  height: 6rem;
  width: 6rem;
}

.c-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.c-logo__image {
  width: 150px;
  max-width: 40rem;
  background: transparent;
}

/* Dropdown */
.dropdown {
  display: inline-block;
  position: relative;
}

.dd-button {
  display: inline-block;
  text-align: left;
  padding-left: 2rem;
  min-width: 42.25rem;
  cursor: pointer;
  white-space: nowrap;
}
.dd-button[disabled] {
  cursor: not-allowed;
  background-color: #8ca608;
  color: #fff;
  border-color: #8ca608;
  opacity: 0.3;
}
.dd-button[disabled]:hover {
  background-color: #8ca608;
}

.dd-button:after {
  content: "";
  text-align: end;
  position: absolute;
  top: 2.5rem;
  right: 2rem;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
}

.dd-button:hover {
  background-color: #6c800d;
}

.dd-button-no-styles {
  border: 0;
  font-weight: normal;
  padding-top: 0;
  padding-bottom: 0;
}

.dd-button-bare {
  display: inline-block;
  text-align: left;
  padding-left: 2rem;
  min-width: 21.125rem;
  cursor: pointer;
  white-space: nowrap;
}

.dd-button-bare:after {
  content: "";
  justify-self: end;
  position: absolute;
  top: 2.5rem;
  right: 2rem;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #8ca608;
}

.dd-button-bare:hover {
  background-color: #fff;
}

.dd-input {
  display: none;
}

.dd-menu {
  position: relative;
  top: 100%;
  border: 1px solid #ccc;
  border-radius: 1rem;
  padding-left: 0;
  padding: 2rem;
  margin: 0.75rem 0 0 0;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.3019607843);
  background-color: #fff;
  list-style-type: none;
  z-index: 1;
}
.dd-menu__subitem {
  border-color: transparent;
  color: #8ca608;
  background: transparent;
  padding: 0;
  border: 0;
  min-width: auto;
}
.dd-menu__subitem:hover, .dd-menu__subitem:focus, .dd-menu__subitem__active {
  cursor: pointer;
  color: #6c800d;
  border-color: transparent;
  background-color: #6c800d;
}

.dd-input + .dd-menu {
  display: none;
}

.dd-input:checked + .dd-menu {
  display: block;
}

.dd-menu li.divider {
  padding: 0;
  border-bottom: 1px solid #cccccc;
}

.c-menu__link {
  display: block;
  width: 100%;
  margin: 0.5rem 0 0.3rem;
  font-weight: bold;
  color: #8ca608;
  border-radius: 100px;
}
.c-menu__link:hover {
  color: #8ca608;
}
.c-menu__link.is-active > .c-menu__link {
  color: #fff;
  background-color: #8ca608;
}
.c-menu__link.is-active::before {
  color: #8ca608;
}
.c-menu__link.is-active .c-menu__icon {
  background-color: #8ca608;
  color: #fff;
}
.c-menu__subList {
  list-style: none;
  margin: 0 0 0 1.7rem;
  padding-left: 0rem;
}
.c-menu__subItem {
  line-height: 2.25rem;
  text-transform: capitalize;
  position: relative;
  display: flex;
  align-items: center;
}
.c-menu__subItem > .c-menu__link {
  display: inline-flex;
  font-size: 2rem;
}
.c-menu__subItem > .c-menu__link::before {
  display: inline-block;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 2rem;
  content: "●";
  color: transparent;
}
.c-menu__subItem > .c-menu__link.is-active {
  color: #8ca608;
}
.c-menu__subItem > .c-menu__link.is-active > .c-menu__link {
  color: #8ca608;
}
.c-menu__subItem > .c-menu__link.is-active::before {
  color: #8ca608;
}
.c-menu__subItem > .c-menu__link.is-active .c-menu__icon {
  background-color: #8ca608;
  color: #fff;
}
.c-menu__subItem:hover {
  color: #8ca608;
}
.c-menu__subItem:hover > .c-menu__link::before {
  color: #8ca608;
}

.c-primary-nav__list {
  list-style: none;
  display: flex;
}
@media all and (max-width: 70em) {
  .c-primary-nav__list {
    display: none;
  }
}
.c-primary-nav__inner {
  padding: 0 2rem;
}
.c-primary-nav__dropdown {
  list-style: none;
  display: flex;
  flex-direction: column;
}
.c-primary-nav__item {
  margin: 0 1rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
.c-primary-nav__item > .c-primary-nav__link {
  display: inline-flex;
}
.c-primary-nav__item > .c-primary-nav__link:hover {
  background: #6c800d;
  color: #fff;
  align-items: center;
  margin: 0;
}
.c-primary-nav__item > .c-primary-nav__link.is-active {
  border-radius: 0.5em;
  height: 4rem;
  margin: 0;
  align-items: center;
  background: #8ca608;
  color: #fff;
}
.c-primary-nav__link {
  color: #fff;
}
.c-primary-nav__link:hover {
  border-radius: 0.5em;
  height: 4rem;
  margin: 0;
}

.c-mobile-nav {
  margin: 1rem 2rem;
}
@media all and (min-width: 70em) {
  .c-mobile-nav {
    display: none;
  }
}

.c-tabset, .c-tabset-bare, .c-tabset-vertical {
  display: inline-grid;
  grid-auto-flow: column;
  overflow: auto;
  grid-gap: 1rem;
  padding: 0.75rem;
  background: #edebe8;
  color: #8ca608;
  text-align: center;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  border-radius: 1.5rem;
  margin-bottom: 3rem;
  min-height: 7rem;
}
.c-tabset .c-tab__item, .c-tabset-bare .c-tab__item, .c-tabset-vertical .c-tab__item {
  white-space: nowrap;
  border-radius: 1rem;
  padding: 0.75rem;
  cursor: pointer;
  width: 11.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}
.c-tabset .c-tab__item--active, .c-tabset-bare .c-tab__item--active, .c-tabset-vertical .c-tab__item--active {
  background: #8ca608;
  color: #fff;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.c-tabset-vertical {
  display: block;
  margin-bottom: 0;
}

.c-tabset-bare {
  background: transparent;
  margin: 3rem;
}
.c-tabset-bare .c-tab__item {
  height: 4rem;
  width: 9rem;
  margin: 0 0.5rem;
}
.c-tabset-bare .c-tab__item--active {
  height: 4rem;
  width: 9rem;
}

.c-tabset-skinny {
  display: inline-grid;
  grid-template-columns: repeat(auto-fit, minmax(93px, 1fr));
  grid-auto-flow: column;
  overflow: auto;
  grid-gap: 1rem;
  padding: 0.75rem;
  background: #edebe8;
  color: #8ca608;
  text-align: center;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  border-radius: 1.5rem;
  margin-bottom: 4rem;
  height: 5.5rem;
}
.c-tabset-skinny .c-tab__item {
  white-space: nowrap;
  border-radius: 1rem;
  cursor: pointer;
  min-width: 11.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-tabset-skinny .c-tab__item--active {
  background: #8ca608;
  color: #fff;
  border-radius: 1rem;
  height: 4rem;
}

.c-linkset {
  display: inline-grid;
  grid-template-columns: repeat(auto-fit, minmax(93px, 1fr));
  grid-auto-flow: column;
  overflow: auto;
  background: #edebe8;
  color: #8ca608;
  text-align: center;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  border-radius: 1rem;
  text-align: center;
  height: 5.5rem;
}
.c-linkset .c-tab__item {
  white-space: nowrap;
  border-radius: 1rem;
  padding: 1.15rem 3.5rem;
  cursor: pointer;
  min-width: 12.5rem;
}
.c-linkset .c-tab__item--active {
  background: #8ca608;
  color: #fff;
  border-radius: 0;
}

.c-select {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;
  border: 1px solid #8ca608;
  border-radius: 3rem;
  height: 5rem;
  padding: 0 2rem;
  background-color: transparent;
  cursor: pointer;
}
.c-select--no-border {
  border: none;
}
.c-select--large {
  width: 32rem;
}
.c-select--wide {
  width: 14rem;
}
.c-select--medium {
  width: 20rem;
}
.c-select--small {
  width: 20rem;
  margin: 2.5rem 0;
}
.c-select--disabled::after {
  display: none;
}
.c-select select,
.c-select select.valid {
  color: #8ca608;
  background-color: transparent;
  padding: 0;
}
.c-select select.invalid {
  color: #999790;
}
.c-select::after {
  position: absolute;
  grid-area: select;
  justify-self: end;
  content: "";
  width: 0.8em;
  height: 0.5em;
  background-color: #8ca608;
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
}
.c-select:hover {
  border-color: #8ca608;
}
.c-select:hover select {
  color: #8ca608;
}
.c-select:hover::after {
  background-color: #8ca608;
}
.c-select[disabled] {
  cursor: not-allowed;
  background-color: #fff;
  color: #999790;
  border-color: #e3e1dc;
}
.c-select select[disabled] {
  cursor: not-allowed;
}

.c-status-led {
  display: flex;
  align-items: center;
}
.c-status-led__label {
  text-transform: uppercase;
  font-size: 1.5rem;
}
.c-status-led__label--active {
  color: #41b300;
}
.c-status-led__label--unknown {
  color: #556066;
}
.c-status-led__label--inactive {
  color: #556066;
}
.c-status-led__label--warning {
  color: #e6b12e;
}
.c-status-led__label--error {
  color: #e50000;
}
.c-status-led__light {
  margin-right: 0.5rem;
}
.c-status-led__light--active {
  fill: #41b300;
}
.c-status-led__light--unknown {
  border: 2px solid;
  border-radius: 100px;
  padding: 0.8rem;
}
.c-status-led__light--inactive {
  fill: #556066;
}
.c-status-led__light--warning {
  color: #e6b12e;
}
.c-status-led__light--error {
  color: #e50000;
}
.c-status-led svg {
  width: 5rem;
  height: 5rem;
}

.c-status-label {
  padding: 1rem 1.5rem;
  border-radius: 100px;
  text-transform: uppercase;
  font-size: 1.5rem;
  color: #fff;
}
.c-status-label--false {
  background-color: #66645e;
}
.c-status-label--true {
  background-color: #41b300;
}

.c-status-check svg {
  width: 32px;
  height: 32px;
}
.c-status-check--false svg {
  fill: #66645e;
}
.c-status-check--true svg {
  fill: #41b300;
}

.c-field {
  position: relative;
}
.c-field__filled {
  width: 35rem;
  height: 5.5rem;
  border: 1px solid #8ca608;
  border-radius: 1rem;
  padding: 0.5rem 2rem;
  background: #fff;
}
.c-field__icon {
  top: 0;
  position: absolute;
  padding: 1.5rem 2rem 0 0;
  right: 0;
  background-color: transparent;
  color: #8ca608;
  border: transparent;
  font-size: 2.3rem;
}
.c-field__icon--search__clear {
  display: none;
}
.c-field__icon--search__search {
  display: block;
}
.c-field__icon--search:focus__clear {
  display: block;
}
.c-field__icon--search:focus__search {
  display: none;
}
.c-field__box {
  min-height: 19rem;
}
.c-field__bare {
  border-width: 0 0 1px;
  border-radius: 0;
}
.c-field__bare:focus {
  box-shadow: none;
}
.c-field__icon-error {
  position: absolute;
  padding: 1.6rem 2rem 0 0;
  right: 0;
  color: #e50000;
  font-size: 2.5rem;
}
.c-field__note {
  color: #000;
  width: 26.3rem;
  line-height: 1.25;
}
.c-field__error {
  color: red;
}
.c-field__error-small {
  color: red;
  font-size: 13px;
}
.c-field__error-box {
  border: 2px solid #e50000;
  background-color: #e50000;
  border-radius: 0.25em;
  color: #fff;
  width: 30.3rem;
  height: 13.1rem;
  line-height: 1.25;
}
.c-field__inner {
  padding: 0.5rem 0;
}
.c-field__max {
  max-width: 45rem;
}
.c-field input::placeholder {
  color: #66645e;
}

.c-field .c-input__error,
.c-field .c-input__error:focus {
  border: 2px solid #e50000;
  box-shadow: none;
}

.c-input__login {
  width: 35rem;
}
.c-input__small {
  max-width: 15.5rem;
}
.c-input--no-outline {
  border: none;
  outline: none;
}

.c-form {
  width: 45rem;
}

.c-accordion {
  border-radius: 1rem;
  background-color: #edebe8;
}
.c-accordion__header {
  padding: 1.5rem 2rem;
  border: none;
  cursor: pointer;
  background-color: transparent;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.c-accordion__header__text {
  color: #66645e;
  font-weight: bold;
  font-size: 2rem;
}
.c-accordion__content p {
  padding: 0 2rem 2rem 2rem;
  margin: 0;
}

.Toastify__toast--success {
  background: #41b300 !important;
}

.Toastify__toast--error {
  background: #e50000 !important;
}

.Toastify__toast--warning {
  background: #e6b12e !important;
}

.Toastify__toast--default {
  background: #556066 !important;
  color: #fff !important;
}

.Toastify__toast-container--top-right.wide-toast {
  width: 400px !important;
}

.Toastify__close-button {
  align-self: center !important;
}
.Toastify__close-button svg {
  width: 20px !important;
  height: 20px !important;
}

.c-float {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 42rem;
}
.c-float__input {
  min-height: 5.5rem;
  border-width: 0 0 1px;
  font-size: 2rem;
  padding: 1.5rem 0 0 0;
  border-color: #000;
  color: #66645e;
  border-radius: 0;
}
.c-float__input:required {
  border-color: #e50000;
}
.c-float__input:focus {
  border-color: #000;
  outline: #000;
  box-shadow: none;
}
.c-float__input:disabled {
  cursor: not-allowed;
}
.c-float__note {
  color: #000;
  width: 27rem;
  line-height: 1.25;
}
.c-float__icon {
  position: absolute;
  right: 0;
  font-size: 2.3rem;
  border-width: 0 0 1px;
  padding-bottom: 0.5rem;
  cursor: pointer;
  width: 5.5rem;
  height: 5.5rem;
  background-color: #fff;
  border-color: #000;
}
.c-float__error {
  position: absolute;
  right: 0;
  font-size: 2.3rem;
  border-width: 0 0 1px;
  padding-bottom: 0.5rem;
  cursor: pointer;
  width: 5.5rem;
  height: 5.5rem;
  background-color: #fff;
  border-color: #e50000;
  color: #e50000;
}
.c-float__label {
  text-transform: capitalize;
  position: absolute;
  transform: translate(0, 1.5rem);
  color: #66645e;
  pointer-events: none;
  transform-origin: top left;
  transition: all 0.2s ease-out;
}
.c-float__active {
  transform: translate(-1.2rem, -2.5rem) scale(0.65);
  transition: all 0.2s ease-out;
}
.c-float__password {
  text-transform: capitalize;
  position: absolute;
  transform: translate(0, 1.5rem);
  color: #66645e;
  pointer-events: none;
  transform-origin: top left;
  transition: all 0.2s ease-out;
}
.c-float:focus-within .c-float__password label {
  transform: translate(-1.9rem, -2.5rem) scale(0.65);
  transition: all 0.2s ease-out;
  text-transform: uppercase;
}
.c-float:focus-within label {
  transform: translate(-1.1rem, -2.5rem) scale(0.65);
  transition: all 0.2s ease-out;
  text-transform: uppercase;
}

.c-choose-date {
  position: relative;
}
.c-choose-date .c-btn-clear-date {
  height: 1rem;
  min-height: auto;
}
.c-choose-date .react-datepicker-wrapper {
  max-width: 15.5rem;
}
.c-choose-date .react-datepicker-popper {
  transform: scale(2) translate(0, 60px) !important;
}
.c-choose-date .react-datepicker__tab-loop {
  position: relative;
  z-index: 999;
}
.c-choose-date .react-datepicker__triangle {
  display: none;
}
.c-choose-date .react-datepicker-popper[data-placement^=bottom] {
  padding-top: 0;
}
.c-choose-date .react-datepicker__header {
  background-color: #fff;
  border-bottom: none;
}
.c-choose-date .react-datepicker {
  border: none;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.3019607843);
}
.c-choose-date .react-datepicker__day--keyboard-selected,
.c-choose-date .react-datepicker__day--selected {
  color: #fff !important;
  background-color: #8ca608;
}
.c-choose-date .react-datepicker__day-name {
  margin: 0;
  width: 3rem;
  line-height: 1;
  color: #403d38;
}
.c-choose-date .react-datepicker__current-month {
  margin: 0.5rem 0 1.5rem 0;
  color: #403d38;
}
.c-choose-date .react-datepicker__day {
  border: none;
  margin: 0;
  padding: 1rem;
  line-height: 1;
  width: 3rem;
  border-radius: 0;
  color: #403d38;
}
.c-choose-date .react-datepicker__day:hover {
  border-radius: 0;
}
.c-choose-date .react-datepicker__navigation--previous {
  background: url("styles/assets/icons/chevron-left-green.svg") no-repeat;
  background-position: center;
  transform: scale(0.5);
  border: 1px solid #8ca608;
  border-radius: 100px;
}
.c-choose-date .react-datepicker__navigation--next {
  background: url("styles/assets/icons/chevron-right.svg") no-repeat;
  background-position: center;
  transform: scale(0.5);
  border: 1px solid #8ca608;
  border-radius: 100px;
}
.c-choose-date .react-datepicker__navigation-icon {
  display: none;
}
.c-choose-date .c-date-picker {
  height: auto;
  padding: 1.5rem 2rem 1.5rem 5rem !important;
  cursor: pointer;
  background-image: url("styles/assets/icons/calendar.svg");
  background-repeat: no-repeat;
  background-position: 2rem 50%;
  background-color: transparent;
  color: #8ca608;
  font-weight: bold;
}
.c-choose-date .c-date-picker:focus {
  outline: none;
  box-shadow: none;
}
.c-choose-date .c-date-picker:hover {
  color: #6c800d;
  background-image: url("styles/assets/icons/calendar-hover.svg");
}
.c-choose-date .c-date-picker:hover::placeholder {
  color: #6c800d;
}
.c-choose-date .c-date-picker:disabled {
  opacity: 1;
  color: #66645e;
  background-image: url("styles/assets/icons/calendar-disabled.svg");
  font-weight: normal;
  cursor: text;
}
.c-choose-date .c-date-picker:disabled::placeholder {
  color: #66645e;
}
.c-choose-date .c-date-picker--no-border {
  border: none;
  outline: none;
}
.c-choose-date .c-date-picker::placeholder {
  color: #8ca608;
}
.c-choose-date .c-date-picker::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}

.c-header {
  display: flex;
  background-color: #403d38;
  height: 8rem;
  transition: all 0.4s ease;
}
.c-header__logo {
  height: 6rem;
  padding: 0.5rem 2rem 0.5rem 5rem;
}
.c-header__login {
  height: 6rem;
  margin: 1rem 2rem 1rem 0;
}
.c-header__left {
  display: flex;
  color: #fff;
  font-family: "Merriweather";
  font-size: 3rem;
}
.c-header__right {
  display: flex;
  padding-right: 5rem;
  color: #fff;
  font-family: "Merriweather";
}
@media all and (max-width: 70em) {
  .c-header__right {
    display: none;
  }
}
.c-header__stats {
  margin: 0 2rem;
  font-family: "Roboto", sans-serif;
}
.c-header__text {
  color: #fff;
}
.c-header__sync {
  display: flex;
  flex-direction: column;
  background-color: #403d38;
  color: #fff;
}
.c-header__dd-sync {
  display: flex;
  flex-direction: column;
  background-color: #403d38;
  color: #fff;
  margin: 9rem 2rem 2rem 2rem;
}

.c-header-nav {
  background-color: #403d38;
  height: fit-content;
  transition: all 0.4s ease;
}

label {
  display: block;
  padding-bottom: 0.25rem;
}

.c-toggle {
  position: relative;
  width: 9rem;
  height: 4rem;
  border-radius: 50px;
  border: 0;
  background-color: #edebe8;
  color: #fff;
  overflow: hidden;
  cursor: pointer;
  font-family: "Roboto", "Merriweather", sans-serif;
  font-weight: 800;
}
.c-toggle .c-toggle__handle {
  position: absolute;
  width: 3.25rem;
  height: 3.25rem;
  top: 0.4rem;
  left: 0.5rem;
  font-size: 15px;
  font-weight: bold;
  line-height: 1;
  transition: 0.2s ease all;
  background-color: #66645e;
  border-radius: 50px;
  z-index: 2;
}
.c-toggle__label-active, .c-toggle__label-inactive {
  position: absolute;
  top: 0.5rem;
}
.c-toggle__label-active {
  left: 0.75rem;
  font-size: 2rem;
}
.c-toggle__label-inactive {
  right: 0.75rem;
  color: #66645e;
}
.c-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.c-toggle--active {
  background-color: #8ca608;
}
.c-toggle--active .c-toggle__handle {
  left: 5.3rem;
  right: auto;
  background-color: #fff;
}

.c-toggle-mini {
  position: relative;
  width: 6.375rem;
  height: 4rem;
  border-radius: 50px;
  border: 0;
  background-color: #edebe8;
  color: #fff;
  overflow: hidden;
  cursor: pointer;
  font-family: "Roboto", "Merriweather", sans-serif;
  font-weight: 800;
}
.c-toggle-mini .c-toggle-mini__handle {
  position: absolute;
  width: 3.25rem;
  height: 3.25rem;
  top: 0.4rem;
  left: 0.5rem;
  font-size: 15px;
  font-weight: bold;
  line-height: 1;
  transition: 0.2s ease all;
  background-color: #66645e;
  border-radius: 50px;
  z-index: 2;
}
.c-toggle-mini__label-active, .c-toggle-mini__label-inactive {
  position: absolute;
  top: 0.5rem;
}
.c-toggle-mini__label-active {
  left: 0.75rem;
}
.c-toggle-mini__label-inactive {
  right: 0.75rem;
  color: #66645e;
}
.c-toggle-mini--disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.c-toggle-mini--active {
  background-color: #8ca608;
}
.c-toggle-mini--active .c-toggle-mini__handle {
  left: 2.65rem;
  right: auto;
  background-color: #fff;
}

.c-incrementer {
  display: flex;
}

.c-incrementer-input {
  display: flex;
  align-items: center;
  width: 10rem;
  height: 5.5rem;
  color: #66645e;
  border: 1px solid #8ca608;
  border-radius: 0.5em;
  background: #fff;
  margin: 0 1.25rem;
  padding: 0 2rem;
}

.c-react-table {
  border: 0;
  position: relative;
  display: flex;
  flex-direction: column;
}
.c-react-table__table {
  flex: auto 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
  min-width: auto !important;
}
.c-react-table__table__thead {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  background: #edebe8;
  border: 0;
  border-radius: 10px;
  box-shadow: none !important;
  padding: 1rem 8px;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  width: fit-content;
  min-width: 100%;
}
.c-react-table__table__thead__tr {
  flex: 1 0 auto;
  display: inline-flex !important;
  text-align: left;
  font-size: 1.5rem;
  color: #66645e;
  font-weight: normal;
}
.c-react-table__table__thead__th, .c-react-table__table__thead__td {
  box-shadow: none;
  padding: 5px 5px;
  border-right: 0;
  width: 100px;
  cursor: pointer;
  white-space: normal;
  word-break: break-word;
  overflow: visible;
  line-height: normal;
  position: relative;
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  text-overflow: ellipsis;
}
.c-react-table__table__thead__th__content, .c-react-table__table__thead__td__content {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 2rem;
}
.c-react-table__table__thead__th__sort, .c-react-table__table__thead__td__sort {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  width: 10px;
  top: 0;
  bottom: 0;
  right: 15px;
  z-index: 10;
}
.c-react-table__table__thead__th__sort i, .c-react-table__table__thead__td__sort i {
  color: #556066;
}
.c-react-table__table__thead__th__resizer, .c-react-table__table__thead__td__resizer {
  display: inline-block;
  position: absolute;
  width: 20px;
  top: 0;
  bottom: 0;
  right: -6px;
  cursor: col-resize;
  z-index: 10;
}
.c-react-table__table__tbody {
  margin-left: 5px;
  flex: 99999 1 auto;
  display: flex;
  flex-direction: column;
}
.c-react-table__table__tbody__tr-group {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}
.c-react-table__table__tbody__tr {
  flex: 1 0 auto;
  display: inline-flex;
}
.c-react-table__table__tbody__td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 18px 5px;
  transition: 0.3s ease;
  transition-property: width, min-width, padding, opacity;
  border-right: 0 !important;
  min-height: 6rem;
  display: flex;
  align-items: center;
}
.c-react-table__table__tbody__td__cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.c-react-table__empty-message {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem;
  color: #66645e;
  text-align: center;
}
.c-react-table__pagination {
  background: transparent;
  border: 0;
  border-radius: 10px;
  box-shadow: none;
  padding: 0.5rem;
  row-gap: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
}
.c-react-table__pagination input {
  border-radius: 3rem;
  padding: 0 2rem;
  height: 2.7rem;
  background-color: white;
  border: 0;
  color: #6c800d;
  height: 5rem;
  font-size: inherit;
  font-weight: normal;
  outline-width: 0;
}
.c-react-table__pagination select {
  cursor: pointer;
}
.c-react-table__pagination input,
.c-react-table__pagination select {
  border-radius: 3rem;
  padding: 0 2rem;
  height: 2.7rem;
  background-color: white;
  border: 0;
  color: #6c800d;
  height: 5rem;
}
.c-react-table__pagination__btn {
  color: #8ca608;
  background-color: white;
  width: inherit;
  height: 5rem;
  padding: 0 2rem;
  border-radius: 50px;
  margin-right: 0;
  appearance: none;
  display: block;
  border: 0;
  font-size: 1em;
  transition: all 0.1s ease;
  outline-width: 0;
}
.c-react-table__pagination__btn:hover {
  background-color: #6c800d !important;
}
.c-react-table__pagination__btn[disabled] {
  opacity: 0.5;
  cursor: default;
}
.c-react-table__pagination__btn[disabled]:hover {
  background-color: #edebe8 !important;
  cursor: not-allowed;
}
.c-react-table__pagination__previous {
  flex: 1 1;
  text-align: center;
}
.c-react-table__pagination__next {
  flex: 0 0;
}
.c-react-table__pagination__center {
  flex: 1.5 1;
  text-align: center;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.c-react-table__pagination__center__page-info {
  margin: auto 10px;
  display: inline-block;
  white-space: nowrap;
}
.c-react-table__pagination__center__page-info__page-jump {
  display: inline-block;
}
.c-react-table__pagination__center__page-info__page-jump input {
  border-radius: 20px;
  border: 0;
  background-color: white;
  width: 70px;
  text-align: center;
}
.c-react-table__pagination__center__page-info {
  margin: auto 10px;
}
.c-react-table__pagination__center__page-info select {
  border-radius: 3rem;
  padding: 0 2rem;
  background-color: white;
  border: 0;
  color: #6c800d;
  height: 5rem;
  cursor: pointer;
}

[data-reach-dialog-content] {
  margin: auto !important;
}
@media all and (max-width: 47em) {
  [data-reach-dialog-content] {
    width: 95vw;
  }
}

@keyframes fromTop {
  0% {
    top: -50vh;
    opacity: 0;
  }
  100% {
    top: 0vh;
    opacity: 1;
  }
}
@keyframes toTop {
  0% {
    top: 0vh;
    opacity: 1;
  }
  100% {
    top: -50vh;
    opacity: 0;
  }
}
.c-modal, .c-modal.closed {
  margin: 10vh auto auto;
  max-width: 55rem;
  border-radius: 1rem;
  position: relative;
  overflow: auto;
  animation-name: fromTop;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  top: 0vh;
  opacity: 1;
}
.c-modal .c-btn__icon {
  padding-right: 0;
  height: auto;
}
.c-modal--wide {
  position: relative;
  width: 100% !important;
  max-width: 215rem;
  overflow: hidden;
  margin: 10vh auto auto !important;
  border-radius: 1rem;
}
.c-modal--short {
  min-height: auto;
}
.c-modal__close {
  position: absolute;
  top: 2rem;
  right: 3rem;
}
@media all and (max-width: 400px) {
  .c-modal__close {
    display: none !important;
  }
}
.c-modal__heading {
  margin: 0;
  padding: 1rem 3rem 2rem 3rem;
  font-size: 3rem;
}
.c-modal__body {
  padding: 0rem 3rem 3rem 3rem;
}
.c-modal__section {
  padding: 1rem 0rem;
  min-width: 22rem;
}
.c-modal__section h4 {
  margin: 1rem 0;
}

.c-modal.closed {
  animation-name: toTop;
  top: -50vh;
  opacity: 0;
}

@keyframes fromRight {
  0% {
    right: -50vw;
    opacity: 0;
  }
  100% {
    right: 0vw;
    opacity: 1;
  }
}
@keyframes toRight {
  0% {
    right: 0vw;
    opacity: 1;
  }
  100% {
    right: -50vw;
    opacity: 0;
  }
}
.c-modal-slider, .c-modal-menu-slider, .c-modal-menu-slider.closed, .c-modal-slider.closed {
  position: fixed;
  animation-name: fromRight;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  right: 0;
  height: 100vh;
  border-radius: 5px;
  overflow-x: auto;
  opacity: 1;
  margin: auto;
}
.c-modal-slider--wide {
  width: 85vw;
}
.c-modal-slider__close {
  position: fixed;
  right: 3rem;
}

.c-modal-slider.closed, .closed.c-modal-menu-slider {
  animation-name: toRight;
  right: -50vw;
  opacity: 0;
}

@keyframes fromLeft {
  0% {
    left: -100vw;
    opacity: 0;
  }
  100% {
    left: 0vw;
    opacity: 1;
  }
}
.c-modal-menu-slider, .c-modal-menu-slider.closed {
  animation-name: fromLeft;
  left: 0;
  opacity: 1;
}

@keyframes toLeft {
  0% {
    left: 0vw;
    opacity: 1;
  }
  100% {
    left: -100vw;
    opacity: 0;
  }
}
.c-modal-menu-slider.closed {
  animation-name: toLeft;
  left: -100vw;
  opacity: 0;
}

.c-stats-widget {
  max-width: 54rem;
  padding: 0 3rem;
}
.c-stats-widget__title {
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  font-size: 2rem;
}
.c-stats-widget__stat {
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  font-size: 4rem;
}
.c-stats-widget__section {
  display: flex;
  align-items: center;
}
.c-stats-widget__label {
  text-transform: uppercase;
  font-size: 1.5rem;
  color: #66645e;
}
.c-stats-widget__subtitle {
  font-weight: normal;
  font-family: "Roboto", sans-serif;
  font-size: 2rem;
  padding: 0 0 0.9rem 1rem;
}
.c-stats-widget__content {
  font-weight: normal;
  font-family: "Roboto", sans-serif;
  font-size: 2rem;
  margin-top: 0.5rem;
}
.c-bar-widget {
  max-width: 54rem;
  width: 54rem;
  padding: 0 3rem;
  position: relative;
  background-color: #edebe8;
  border-radius: 24px;
  border-bottom: 20px solid transparent;
  max-height: 54rem;
  margin: 4rem 2rem 0 2rem;
  overflow-y: hidden;
}
.c-bar-widget__header {
  display: flex;
}
.c-bar-widget__group {
  overflow-y: auto;
  max-height: 38rem;
}
.c-bar-widget__title {
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  font-size: 2rem;
  margin-top: 3rem;
}
.c-bar-widget__subtitle {
  font-weight: thin;
  font-family: "Roboto", sans-serif;
  font-size: 2rem;
  margin-bottom: 3rem;
}
.c-bar-widget__stat {
  color: #fff;
  font-size: 10rem;
  margin: -3rem 0 0 0;
}
.c-bar-widget__bar {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  height: 5.5rem;
  background: #cccac0;
  border-radius: 0.5em;
}
.c-bar-widget__lossbar {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  height: 5.5rem;
  background: #cc493d;
  border-radius: 0.5em;
}
.c-bar-widget__label {
  z-index: 1;
  padding: 0 0 0 2rem;
  color: #fff;
}
.c-bar-widget__value {
  z-index: 1;
  padding: 0 2rem 0 0;
  color: #fff;
}
.c-bar-widget__fill {
  position: absolute;
  width: 100%;
  height: 5.5rem;
  border-radius: 0.5em;
  background-color: #5e4cbf;
  z-index: 0;
}
.c-bar-widget__profitfill {
  position: absolute;
  width: 100%;
  height: 5.5rem;
  border-radius: 0.5em;
  background-color: #4cbf4c;
  z-index: 0;
}
.c-bar-widget__empty-text {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.c-bar-widget--empty .c-bar-widget__value,
.c-bar-widget--empty .c-bar-widget__label {
  display: none;
}
.c-bar-widget--empty .c-bar-widget__bar {
  opacity: 0.2;
}

.c-reports {
  max-width: 180rem;
}

.c-box {
  width: 64rem;
  margin: 4rem 2rem 0rem 4rem;
  padding: 3rem 3rem 0rem 3rem;
  background-color: #edebe8;
  border-radius: 24px;
}

.c-stats-widget__stat {
  justify-content: flex-end;
}

.c-stats-widget__subtitle {
  margin-right: 10rem;
}

.c-line-widget {
  padding: 0 3rem 3rem 3rem;
  margin: 3rem 4rem 4rem 4rem;
  position: relative;
  background-color: #edebe8;
  border-radius: 24px;
  max-width: 100%;
  min-width: 110rem;
}
.c-line-widget__title {
  font-weight: bold;
  font-family: "Roboto", sans-serif;
  font-size: 2rem;
  padding-top: 4rem;
}

.c-svg-box {
  height: 75rem;
  margin-left: 2rem;
  margin-bottom: 2rem;
}

.barchart {
  font-family: sans-serif;
}

path {
  fill: none;
}

svg {
  min-height: 5rem;
  height: 10rem;
  width: 100%;
}
svg g {
  color: transparent;
}
svg text {
  color: #556066;
}

.tick line {
  opacity: 0.2;
}


.c-choose-date {
  position: relative;

  .c-btn-clear-date {
    height: 1rem;
    min-height: auto;
  }

  .react-datepicker-wrapper {
    max-width: 15.5rem;
  }

  .react-datepicker-popper {
    transform: scale(2) translate(0, 60px) !important;
  }

  .react-datepicker__tab-loop {
    position: relative;
    z-index: 999;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker-popper[data-placement^="bottom"] {
    padding-top: 0;
  }

  .react-datepicker__header {
    background-color: $color-white;
    border-bottom: none;
  }

  .react-datepicker {
    border: none;
    box-shadow: 0px 1px 8px $color-date-picker-box-shadow;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected {
    color: $color-white !important;
    background-color: $color-date-picker-selected-date;
  }
  .react-datepicker__day-name {
    margin: 0;
    width: 3rem;
    line-height: 1;
    color: $color-date-picker-calendar-text;
  }
  .react-datepicker__current-month {
    margin: 0.5rem 0 1.5rem 0;
    color: $color-date-picker-calendar-text;
  }
  .react-datepicker__day {
    border: none;
    margin: 0;
    padding: 1rem;
    line-height: 1;
    width: 3rem;
    border-radius: 0;
    color: $color-date-picker-calendar-text;

    &:hover {
      border-radius: 0;
    }
  }
  .react-datepicker__navigation--previous {
    background: url("styles/assets/icons/chevron-left-green.svg") no-repeat;
    background-position: center;
    transform: scale(0.5);
    border: 1px solid $color-date-picker-chevron;
    border-radius: 100px;
  }
  .react-datepicker__navigation--next {
    background: url("styles/assets/icons/chevron-right.svg") no-repeat;
    background-position: center;
    transform: scale(0.5);
    border: 1px solid $color-date-picker-chevron;
    border-radius: 100px;
  }

  .react-datepicker__navigation-icon {
    display: none;
  }

  .c-date-picker {
    height: auto;
    padding: 1.5rem 2rem 1.5rem 5rem !important;
    cursor: pointer;
    background-image: url("styles/assets/icons/calendar.svg");
    background-repeat: no-repeat;
    background-position: 2rem 50%;
    background-color: transparent;
    color: $color-date-picker-text;
    font-weight: $font-weight-bold;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:hover {
      color: $color-date-picker-text-hover;
      background-image: url("styles/assets/icons/calendar-hover.svg");

      &::placeholder {
        color: $color-date-picker-text-hover;
      }
    }

    &:disabled {
      opacity: 1;
      color: $color-date-picker-text-disabled;
      background-image: url("styles/assets/icons/calendar-disabled.svg");
      font-weight: $font-weight-normal;
      cursor: text;

      &::placeholder {
        color: $color-date-picker-text-disabled;
      }
    }
    
    &--no-border {
      border: none; 
      outline: none;
    }

    &::placeholder {
      color: $color-date-picker-text;
    }

    &::-webkit-calendar-picker-indicator {
      background: none;
      display: none;
    }
  }
}

.c-status-check {
    svg {
        width: 32px;
        height: 32px;
    }

    &--false {
        svg {
            fill: $color-status-check-false;
        }
    }

    &--true {
        svg {
            fill: $color-status-check-true;
        }
    }
  }
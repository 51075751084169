.c-field {
  position: relative;

  &__filled {
    width: 35rem;
    height: 5.5rem;
    border: 1px solid #8ca608;
    border-radius: 1rem;
    padding: 0.5rem 2rem;
    background: #fff;
  }

  &__icon {
    top: 0;
    position: absolute;
    padding: 1.5rem 2rem 0 0;
    right: 0;
    background-color: transparent;
    color: $color-form-icon;
    border: transparent;
    font-size: 2.3rem;

    &--search {
      &__clear {
        display: none;
      }

      &__search {
        display: block;
      }

      &:focus {
        &__clear {
          display: block;
        }

        &__search {
          display: none;
        }
      }
    }
  }

  &__box {
    min-height: 19rem;
  }

  &__bare {
    border-width: 0 0 1px;
    border-radius: 0;

    &:focus {
      box-shadow: none;
    }
  }

  &__icon-error {
    position: absolute;
    padding: 1.6rem 2rem 0 0;
    right: 0;
    color: $color-form-error;
    font-size: 2.5rem;
  }

  &__note {
    color: $color-form-info;
    width: 26.3rem;
    line-height: 1.25;
  }

  &__error {
    color: red;
  }

  &__error-small {
    color: red;
    font-size: 13px;
  }

  &__error-box {
    border: $border-thickness solid $color-form-error;
    background-color: $color-form-error;
    border-radius: $border-radius-sm;
    color: $color-form-bg;
    width: 30.3rem;
    height: 13.1rem;
    line-height: 1.25;
  }

  &__inner {
    padding: 0.5rem 0;
  }

  &__max {
    max-width: 45rem;
  }

  input::placeholder {
    color: $color-form-placeholder;
  }
}

.c-field .c-input__error,
.c-field .c-input__error:focus {
  border: $border-thickness solid $color-form-error;
  box-shadow: none;
}

.c-input {
  &__login {
    width: 35rem;
  }
  &__small {
    max-width: 15.5rem;
  }
  &--no-outline {
    border: none; 
    outline: none;
  }
}

.c-form {
  width: 45rem;
}

$color-primary: #404d03;

$color-black: #000;
$color-black-shadow: #0000004d;
$color-gray-700: #403d38;
$color-gray-600: #556066;
$color-gray-500: #66645e;
$color-gray-400: #999790;
$color-gray-300: #cccac0;
$color-gray-200: #e3e1dc;
$color-gray-100: #edebe8;
$color-white: #fff;

$color-action-green-500: #8ca608;
$color-action-green-300: #6c800d;

$color-action-blue-500: #0088cc;
$color-action-blue-300: #17a1e6;
$color-action-blue-700: #0a4766;
$color-action-blue-200: #81d4fa;
$color-action-blue-100: #85a3b3;

$color-success-green-500: #41b300;
$color-success-green-700: #338c00;
$color-caution-yellow-500: #e6b12e;
$color-caution-yellow-700: #bf9426;
$color-warning-red-500: #e50000;
$color-warning-red-700: #b30000;

$color-secondary-blue: #39a7bf;
$color-secondary-orange: #e6902e;
$color-secondary-coral: #cc493d;
$color-secondary-purple: #5e4cbf;
$color-secondary-magenta: #ad4cbf;
$color-secondary-teal: #4cbfbb;

// Font/text values
$font-family-primary: "Roboto", sans-serif;
$font-family-secondary: "Merriweather";
$font-family-mono: "Roboto Mono", monospace;
$font-style-normal: normal;
$font-weight-thin: thin;
$font-weight-normal: normal;
$font-weight-bold: bold;
$font-size-default: 8px;
$font-size-sm: 1.5rem; // 12px
$font-size-med: 2rem; // 16px
$font-size-lg: 3rem; // 24px
$font-size-xl: 4rem; // 32px
$character-spacing-0: 0px;
$line-spacing-16: 16px;
$line-spacing-18: 18px;
$line-spacing-21: 21px;
$line-spacing-22: 22px;
$line-spacing-30: 30px;
$text-transform-uppercase: uppercase;

// LAYOUT
// Max Width
$l-max-width: 80rem !default;
$l-max-width-narrow: 45rem !default;
$l-linelength-width: 36rem !default;

// SPACING
// Spacing and offsets --> Used to space grids and body padding
$spacing: 1rem;
$spacing-xs: 0.4 * $spacing;
$spacing-small: 0.5 * $spacing;
$spacing-med: 1.5 * $spacing;
$spacing-large: 2 * $spacing;
$spacing-xlarge: 4 * $spacing;
$spacing-xxlarge: 5 * $spacing;

// BREAKPOINTS
// Breakpoints used in media queries --> These are not the only breakpoints used, but they provide a few defaults
$bp-small: 28em;
$bp-small-2: 35em;
$bp-med: 47em;
$bp-large: 60em;
$bp-xl: 70em;

// #BORDERS
$border-thin: 1px;
$border-thickness: 2px;
$border-radius: 0.5em;
$border-radius-sm: 0.25em;
$border-radius-lg: 100px;

// #ANIMATION
$anim-fade-quick: 0.15s;
$anim-fade-long: 0.5s;
$anim-ease: ease-out;

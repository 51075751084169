// #TEXT
$color-body-text: $color-gray-500;
$color-body-bg: $color-white;
$color-error: $color-warning-red-500;
$color-success: $color-success-green-500;

// #BUTTONS
$color-btn-primary: $color-white !default;
$color-btn-primary-bg: $color-action-green-500 !default;
$color-btn-primary-bg-hover: $color-action-green-300 !default;
$color-btn-primary-border: $color-action-green-500 !default;
$color-btn-primary-disabled: $color-action-green-500 !default;

$color-btn-secondary: $color-action-green-500 !default;
$color-btn-secondary-hover: $color-action-green-300 !default;
$color-btn-secondary-bg: $color-white !default;
$color-btn-secondary-bg-hover: $color-action-green-300 !default;
$color-btn-secondary-text-hover: $color-white !default;
$color-btn-secondary-border: $color-action-green-500 !default;

$color-btn-dark: $color-white !default;
$color-btn-dark-bg: $color-action-green-500 !default;
$color-btn-dark-bg-hover: $color-action-green-500 !default;

$color-btn-outline: $color-action-green-500;
$color-btn-outline-bg: transparent !default;
$color-btn-outline-bg-hover: $color-action-green-500 !default;

$color-btn-filter-border: $color-action-green-500 !default;
$color-btn-filter-text: $color-gray-500 !default;

$color-btn-light: $color-action-green-300 !default;
$color-btn-light-bg: $color-white !default;
$color-btn-light-text-hover: $color-white !default;
$color-btn-light-bg-hover: $color-action-green-300 !default;

// #TOGGLE
$color-toggle-bg: $color-gray-100 !default;
$color-toggle-bg-active: $color-action-green-500 !default;
$color-toggle-handle: $color-gray-500 !default;
$color-toggle-handle-active: $color-white !default;
$color-toggle-label: $color-gray-700 !default;
$color-toggle-label-active: $color-white !default;

// #TABSETS
$color-tabset-text: $color-action-green-500 !default;
$color-tabset-bg: $color-gray-100 !default;
$color-tabset-bg-active: $color-action-green-500 !default;

$color-tabset-text-active: $color-white !default;
$color-bare-bg: $color-white !default;

// #MENU
$color-menu-bg: $color-action-green-300 !default;
$color-menu-bg-active: $color-action-green-500 !default;
$color-menu-text: $color-white !default;
$color-menu-text-active: $color-action-green-500 !default;
$color-dropdown-disabled: $color-action-green-500 !default;
$color-text-disabled: $color-white !default;

// #STATUS LED
$color-status-led-active: $color-success-green-500 !default;
$color-status-led-unknown: $color-gray-600 !default;
$color-status-led-inactive: $color-gray-600 !default;
$color-status-led-warning: $color-caution-yellow-500 !default;
$color-status-led-error: $color-warning-red-500 !default;

// #STATUS LABEL
$color-status-label-false: $color-gray-500 !default;
$color-status-label-true: $color-success-green-500 !default;

// #STATUS CHECK
$color-status-check-false: $color-gray-500 !default;
$color-status-check-true: $color-success-green-500 !default;

// #CHECKBOX
$color-checkbox-bg: $color-action-green-500 !default;
$color-checkbox-border-active: $color-action-green-500 !default;
$color-checkbox-text: $color-gray-500 !default;
$color-checkbox-text-active: $color-white !default;

// #HEADER
$color-header-bg: $color-gray-700 !default;
$color-header-text: $color-white !default;

// #TABLE
$color-btn-outline: $color-action-green-500 !default;
$color-table-header-bg: $color-gray-100 !default;
$color-table-text: $color-gray-500 !default;

// #ACCORDION
$color-accordion-bg: $color-gray-100 !default;
$color-accordion-header-text: $color-gray-500 !default;

// #ALERT
$color-alert-success: $color-success-green-500 !default;
$color-alert-error: $color-warning-red-500 !default;
$color-alert-warning: $color-caution-yellow-500 !default;
$color-alert-default: $color-gray-600 !default;

// FORMS
$color-form: $color-gray-500 !default;
$color-form-bg: $color-white !default;
$color-form-border: $color-action-green-500 !default;
$color-form-border-focus: $color-action-green-500 !default;
$color-form-icon: $color-action-green-500 !default;
$color-form-error: $color-warning-red-500 !default;
$color-form-label: $color-gray-500 !default;
$color-form-info: $color-black;
$color-form-placeholder: $color-gray-500;

$color-form-disabled: $color-gray-400 !default;
$color-form-bg-disabled: $color-white !default;
$color-form-border-disabled: $color-gray-200 !default;

// #FLOATING LABEL
$color-float-outline: $color-black !default;
$color-float-text: $color-gray-500 !default;
$color-float-error: $color-warning-red-500 !default;

// #DATE PICKER
$color-date-picker-box-shadow: $color-black-shadow !default;
$color-date-picker-text: $color-action-green-500 !default;
$color-date-picker-text-hover: $color-action-green-300 !default;
$color-date-picker-text-disabled: $color-gray-500 !default;
$color-date-picker-calendar-text: $color-gray-700 !default;
$color-date-picker-selected-date: $color-action-green-500 !default;
$color-date-picker-chevron: $color-action-green-500 !default;

// REPORTS
$color-report-border: $color-gray-100 !default;
$color-report-bg: $color-gray-100 !default;
$color-report-stat-text: $color-white !default;
$color-report-label: $color-gray-500 !default;
$color-report-content: $color-white !default;
$color-report-bar-bg: $color-gray-300 !default;
$color-report-bar-label: $color-white !default;
$color-report-bar-fill: $color-secondary-purple !default;

.c-float {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 42rem;

    &__input {
    min-height: 5.5rem;
    border-width: 0 0 1px;
    font-size: 2rem;
    padding: 1.5rem 0 0 0;
    border-color: $color-float-outline;
    color: $color-float-text;
    border-radius: 0;

    &:required {
      border-color: $color-float-error;
    }
    
    &:focus {
        border-color: $color-float-outline;
        outline: $color-float-outline;
        box-shadow: none;
    }
    &:disabled {
        cursor: not-allowed;
    }
}


&__note {
    color: $color-form-info;
    width: 27rem;
    line-height: 1.25;
  }

  &__icon {
    position: absolute;
    right: 0;
    font-size: 2.3rem;
    border-width: 0 0 1px;
    padding-bottom: .5rem;
    cursor: pointer;
    width: 5.5rem;
    height: 5.5rem;
    background-color: $color-btn-primary;
    border-color: $color-float-outline;
    
}
&__error {
    position: absolute;
    right: 0;
    font-size: 2.3rem;
    border-width: 0 0 1px;
    padding-bottom: .5rem;
    cursor: pointer;
    width: 5.5rem;
    height: 5.5rem;
    background-color: $color-btn-primary;
    border-color: $color-float-error;
    color: $color-float-error;
}


  &__label {
      text-transform: capitalize;
      position: absolute;
      transform: translate(0, 1.5rem);
      color: $color-float-text;
      pointer-events: none;
      transform-origin: top left;
      transition: all 0.2s ease-out;
    }
    
    &__active {
        transform: translate(-1.2rem, -2.5rem) scale(.65);
        transition: all 0.2s ease-out;
    }
    &__password {
        text-transform: capitalize;
        position: absolute;
        transform: translate(0, 1.5rem);
        color: $color-float-text;
        pointer-events: none;
        transform-origin: top left;        
        transition: all 0.2s ease-out;
        
    }
    &:focus-within &__password label {
        transform: translate(-1.9rem, -2.5rem) scale(0.65);
        transition: all 0.2s ease-out;
        text-transform: uppercase;
        
    }
    &:focus-within label{
        transform: translate(-1.1rem, -2.5rem) scale(0.65);
        transition: all 0.2s ease-out;
        text-transform: uppercase;
    }
  }
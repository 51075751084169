.c-header {
  display: flex;
  background-color: $color-header-bg;
  height: 8rem;
  transition: all 0.4s ease;

  &__logo {
    height: 6rem;
    padding: 0.5rem 2rem 0.5rem 5rem;
  }
  &__login {
    height: 6rem;
    margin: 1rem 2rem 1rem 0;
  }
  &__left {
    display: flex;
    color: $color-header-text;
    font-family: $font-family-secondary;
    font-size: $font-size-lg;
  }
  &__right {
    display: flex;
    padding-right: 5rem;
    color: $color-header-text;
    font-family: $font-family-secondary;

    @media all and (max-width: $bp-xl) {
      display: none;
    }
  }
  &__stats {
    margin: 0 2rem;
    font-family: $font-family-primary;
  }
  &__text {
    color: $color-header-text;
  }
  &__sync {
    display: flex;
    flex-direction: column;
    background-color: $color-header-bg;
    color: $color-header-text;
  }
  &__dd-sync {
    display: flex;
    flex-direction: column;
    background-color: $color-header-bg;
    color: $color-header-text;
    margin: 9rem 2rem 2rem 2rem;
  }
}

.c-header-nav {
  background-color: $color-header-bg;
  height: fit-content;
  transition: all 0.4s ease;
}

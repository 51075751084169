* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  margin: 0px;
}

// important fighting to
html > body {
  padding: 0 !important;
}

input {
  background-color: transparent;
}

button {
  cursor: pointer;
}

a {
  cursor: pointer;
  text-decoration: none;
}

svg {
  overflow: visible;
}

// abstracts
@import "./styles/abstracts/variables";
@import "./styles/abstracts/colors";
@import "./styles/abstracts/mixins";

// utilities
@import "./styles/utilities/spacing";
@import "./styles/utilities/text";
@import "./styles/utilities/cursor";

// vendors
@import "./styles/vendors/reachui";
@import "./styles/vendors/typeahead";

// layout
@import "./styles/layout/layout";

// atoms
@import "./styles/atoms/checkbox";
@import "./styles/atoms/icon";

// base
@import "./styles/base/body";
@import "./styles/base/headings";
@import "./styles/base/text";
@import "./styles/base/forms";

// molecules
@import "./styles/molecules/button";
@import "./styles/molecules/logo";
@import "./styles/molecules/menu";
@import "./styles/molecules/navigation";
@import "./styles/molecules/tabset";
@import "./styles/molecules/select";
@import "./styles/molecules/statusLed";
@import "./styles/molecules/statusLabel";
@import "./styles/molecules/statusCheck";
@import "./styles/molecules/textField";
@import "./styles/molecules/accordion";
@import "./styles/molecules/alert";
@import "./styles/molecules/floatingLabel";
@import "./styles/molecules/datePicker";

// components
@import "./styles/components/Header/header";
@import "./styles/components/ToggleButton/toggleButton";
@import "./styles/components/NumberIncrementer/incrementer";
@import "./styles/components/ReactTable/reactTable";
@import "./styles/components/Modal/modal";
@import "./styles/components/Reports/reports";
@import "./styles/components/Reports/barchart";

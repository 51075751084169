.c-status-label {
    padding: 1rem 1.5rem;
    border-radius: 100px;
    text-transform: uppercase;
      font-size: $font-size-sm;
      color: $color-white;
    &--false {
        background-color: $color-status-label-false;
    }

    &--true {
        background-color: $color-status-label-true;
    }
  }
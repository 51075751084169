/*------------------------------------*\
    #FORMS
\*------------------------------------*/

/**
 * 1) Form element base styles
 */

// Input placeholder text base styles
::-webkit-input-placeholder {
  color: $color-form-placeholder;
}

::-moz-placeholder {
  color: $color-form-placeholder;
}

:-ms-input-placeholder {
  color: $color-form-placeholder;
}

// Fieldset base styles
fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

// Legend base styles
legend {
  margin-bottom: 0.25rem;
}

// Label base styles
label {
  display: block;
  padding-bottom: 0.25rem;
  color: $color-form-label;
}

// Add font size 100% of form element and margin 0 to these elements
button,
input,
select,
textarea {
  font-size: $font-size-med;
  margin: 0;
  color: $color-form;
}

// Input base styles
input {
  width: 100%;
  height: 5.5rem;
  border: $border-thin solid $color-form-border;
  border-radius: 1rem;
  padding: 0.5rem 2rem;
  background: $color-form-bg;

  &:focus {
    border-color: $color-form-border-focus;
    outline: $color-form-border-focus;
    box-shadow: 0 0 0 0.05em lighten($color-form-border-focus, 35%),
      0 0 0.15em 0.1em lighten($color-form-border-focus, 35%);
  }

  &:disabled {
    border-color: $color-form-border-disabled;
    background: $color-form-bg-disabled;
    color: $color-form-placeholder;
    cursor: text;
  }
}

// Remove webkit appearance styles from these elements
input[type="text"],
input[type="tel"],
input[type="password"],
input[type="search"],
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="url"],
input[type="number"],
textarea {
  -webkit-appearance: none;
}

// Checkbox and radio button base styles
input[type="checkbox"],
input[type="radio"] {
  width: auto;
}

// Search input base styles
input[type="search"] {
  -webkit-appearance: none;
}

// Create asterisk for password fields
input[type="password"] {
  -webkit-text-security: square;
}

textarea {
  width: 100%;
  border: $border-thin solid $color-form-border;
  border-radius: 1rem;
  padding: 2rem;
  background: $color-form-bg;
  resize: none;

  &:focus {
    border-color: $color-form-border-focus;
    outline: $color-form-border-focus;
    box-shadow: 0 0 0 0.05em lighten($color-form-border-focus, 35%),
      0 0 0.15em 0.1em lighten($color-form-border-focus, 35%);
  }

  &:disabled {
    border-color: $color-form-border-disabled;
    background: $color-form-bg-disabled;
    color: $color-form-placeholder;
    cursor: text;
  }
}

// // Select –-> Remove default styling
select {
  // A reset of styles, including removing the default dropdown arrow
  border: $border-thickness solid $color-btn-primary-border;
  background-color: $color-btn-primary-bg;
  color: $color-btn-primary;
  padding: 1.5rem 0 1.5rem 2rem;
  display: inline-block;
  text-align: left;
  white-space: nowrap;
  font-weight: bold;
  appearance: none;
  border: none;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  line-height: inherit;
  transition: all 0.4s ease;
  transition: all $anim-fade-quick $anim-ease;
  border-radius: $border-radius;

  &:hover,
  &:focus {
    transition: all 0.4s ease;
    color: $color-btn-primary;
    background-color: $color-btn-primary-bg-hover;
    border-color: $color-btn-primary-bg-hover;
  }

  &:disabled,
  &[disabled] {
    background-color: $color-btn-primary-disabled;
    cursor: text;
    border-color: $color-btn-primary-disabled;
  }

  &:after {
    content: "";
    text-align: end;
    position: absolute;
    top: 2.5rem;
    right: 2rem;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #fff;
  }

  // Stack above custom arrow
  // z-index: 1;

  // Remove dropdown arrow in IE10 & IE11
  // @link https://www.filamentgroup.com/lab/select-css.html
  &::-ms-expand {
    display: none;
  }

  // Remove focus outline, will add on alternate element
  outline: none;
}

form {
  button {
    display: inline-flex;
    align-items: center;
    svg {
      vertical-align: middle;
      fill: $color-white;
      margin-right: 5px;
    }
  }
}

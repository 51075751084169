/* Dropdown */

.dropdown {
  display: inline-block;
  position: relative;
}

.dd-button {
  @extend .c-btn;
  display: inline-block;
  text-align: left;
  padding-left: 2rem;
  min-width: 42.25rem;
  cursor: pointer;
  white-space: nowrap;

  &[disabled] {
    cursor: not-allowed;
    background-color: $color-dropdown-disabled;
    color: $color-text-disabled;
    border-color: $color-dropdown-disabled;
    opacity: 0.3;

    &:hover {
      background-color: $color-dropdown-disabled;
    }
  }
}

.dd-button:after {
  content: "";
  text-align: end;
  position: absolute;
  top: 2.5rem;
  right: 2rem;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid $color-menu-text;
}

.dd-button:hover {
  background-color: $color-menu-bg;
}

.dd-button-no-styles {
  @extend .c-btn-bare;
  border: 0;
  font-weight: $font-weight-normal;
  padding-top: 0;
  padding-bottom: 0;
}

.dd-button-bare {
  @extend .c-btn-bare;
  display: inline-block;
  text-align: left;
  padding-left: 2rem;
  min-width: 21.125rem;
  cursor: pointer;
  white-space: nowrap;
}

.dd-button-bare:after {
  content: "";
  justify-self: end;
  position: absolute;
  top: 2.5rem;
  right: 2rem;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid $color-menu-bg-active;
}

.dd-button-bare:hover {
  background-color: $color-menu-text;
}

.dd-input {
  display: none;
}

.dd-menu {
  position: relative;
  top: 100%;
  border: 1px solid #ccc;
  border-radius: 1rem;
  padding-left: 0;
  padding: 2rem;
  margin: 0.75rem 0 0 0;
  box-shadow: 0px 1px 8px #0000004d;
  background-color: $color-menu-text;
  list-style-type: none;
  z-index: 1;

  &__subitem {
    @extend .c-btn;
    border-color: transparent;
    color: $color-btn-primary-bg;
    background: transparent;
    padding: 0;
    border: 0;
    min-width: auto;

    &:hover,
    &:focus,
    &__active {
      cursor: pointer;
      color: $color-btn-primary-bg-hover;
      border-color: transparent;
      background-color: $color-btn-primary-bg-hover;
    }
  }
}

.dd-input + .dd-menu {
  display: none;
}

.dd-input:checked + .dd-menu {
  display: block;
}

.dd-menu li.divider {
  padding: 0;
  border-bottom: 1px solid #cccccc;
}

// Menu with hidden Bullet Points

.c-menu {
  &__link {
    display: block;
    width: 100%;
    margin: 0.5rem 0 0.3rem;
    font-weight: bold;
    color: $color-menu-text-active;
    border-radius: 100px;
    &:hover {
      color: $color-menu-text-active;
    }

    &.is-active {
      & > .c-menu__link {
        color: $color-white;
        background-color: $color-menu-text-active;
      }
      &::before {
        color: $color-menu-text-active;
      }

      .c-menu__icon {
        background-color: $color-menu-text-active;
        color: $color-white;
      }
    }
  }

  &__subList {
    list-style: none;
    margin: 0 0 0 1.7rem;
    padding-left: 0rem;
  }

  &__subItem {
    line-height: 2.25rem;
    text-transform: capitalize;
    position: relative;
    display: flex;
    align-items: center;

    & > .c-menu__link {
      display: inline-flex;
      font-size: $font-size-med;
      &::before {
        display: inline-block;
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 2rem;
        content: "\25CF";
        color: transparent;
      }
      &.is-active {
        color: $color-menu-text-active;
        & > .c-menu__link {
          color: $color-menu-text-active;
        }
        &::before {
          color: $color-menu-text-active;
        }
        .c-menu__icon {
          background-color: $color-menu-text-active;
          color: $color-white;
        }
      }
    }

    &:hover {
      color: $color-menu-text-active;
      & > .c-menu__link::before {
        color: $color-menu-text-active;
      }
    }
  }
}

.c-accordion {
    border-radius: 1rem;
    background-color: $color-accordion-bg;

    &__header {
        padding: $spacing-med $spacing-large;
        border: none;
        cursor: pointer;
        background-color: transparent;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__text {
            color: $color-accordion-header-text;
            font-weight: $font-weight-bold;
            font-size: $font-size-med;
        }
    }

    &__content {

        p {
            padding: 0 $spacing-large $spacing-large $spacing-large;
            margin: 0;
        }
    }
}
.rbt {
  color: $color-btn-outline;
  z-index: inherit;

  &:after {
    content: "";
    text-align: end;
    position: absolute;
    top: 2.5rem;
    right: 2rem;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #fff;
  }

  .rbt-input-main {
    &::placeholder {
      color: $color-white;
    }
  }
}

.typeahead-secondary {
  .rbt-input {
    background-color: transparent;
    color: $color-form;
    font-weight: $font-weight-normal;

    
    &:hover,
    &:focus {
      background-color: transparent;
      color: $color-btn-secondary-hover;
    }

    &:disabled {
      background-color: transparent;
      color: $color-btn-secondary;
      opacity: 1;
      border-color: $color-form-border-disabled;
      font-weight: $font-weight-bold;
    }
  }

  .rbt {
    &:after {
      display: none;
    }
  }
}

.rbt-input {
  border: $border-thickness solid $color-btn-primary-border;
  background-color: $color-btn-primary-bg;
  color: $color-btn-primary;
  padding: 1.5rem 2rem;
  display: inline-block;
  text-align: left;
  white-space: nowrap;
  font-weight: bold;
  appearance: none;
  border: none;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  line-height: inherit;
  transition: all 0.4s ease;
  transition: all $anim-fade-quick $anim-ease;
  border-radius: $border-radius;

  &:hover,
  &:focus {
    transition: all 0.4s ease;
    color: $color-btn-primary;
    background-color: $color-btn-primary-bg-hover;
    border-color: $color-btn-primary-bg-hover;
  }

  &:disabled,
  &[disabled] {
    background-color: $color-btn-primary-disabled;
    cursor: text;
    opacity: 0.4;
    color: $color-btn-primary;
    border-color: $color-btn-primary-disabled;
  }
}

.rbt-input-main {
  border: 1px solid $color-btn-outline;
  &::placeholder {
    color: $color-btn-outline;
  }
}

.rbt-menu {
  position: relative;
  top: 100%;
  border: 1px solid #ccc;
  border-radius: 1rem;
  padding-left: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin: 0.75rem 0 0 0;
  box-shadow: 0px 1px 8px #0000004d;
  background-color: $color-menu-text;
  list-style-type: none;
  display: flex !important;
  flex-direction: column;
  z-index: 99999;

  a {
    display: block;
  }
}

.rbt-menu li {
  padding-left: 1rem;
  border-left: 3px solid transparent;
  &:hover {
    border-color: $color-btn-outline-bg-hover;
  }
  width: 100%;
  > a {
    display: block;
    width: 100%;
  }
}

.rbt-menu > .dropdown-item {
  color: $color-menu-text-active;
  padding: 0.5rem;
  &::before {
    display: inline-block;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 2rem;
    content: "\25CF";
    color: transparent;
  }
  &.is-active {
    color: $color-menu-text-active;
    > a {
      color: $color-menu-text-active;
    }
    &::before {
      color: $color-menu-text-active;
    }
  }
  &:hover {
    color: $color-menu-text-active;
    &::before {
      color: $color-menu-text-active;
    }
  }
}

.rbt-menu .dropdown-header {
  cursor: default;
  background: $color-white;
  &:hover {
    border-color: transparent !important;
  }
}

.rbt-aux {
  right: 8rem;

  .rbt-close {
    margin-top: 0;
    background-color: transparent;
    border: $border-thin solid $color-menu-text-active;
    border-radius: 100px;
    margin-right: 3rem;
    font-weight: 100;
    overflow: hidden;
    display: flex;
    height: 2.5rem;
    min-width: 2.5rem;
    color: $color-menu-text-active;
    .rbt-close-content {
      font-size: 2.4rem;
      transform: translate(-0.25rem, -0.6rem);
    }
  }
}

.rbt-input-hint {
  color: transparent !important;
  overflow: hidden;
  width: 100%;
}

.rbt-input-multi {
  max-width: 100%;
  .rbt-input-hint-container {
    &::after {
      display: none;
    }
  }

  .rbt-input-wrapper {
    margin: 1px 45px 1px 0px;
    max-height: 8rem;
    overflow: auto;
    background-color: white;
    border-radius: 1rem;
    padding: 0 0 0 7px;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.rbt-input-multi.focus {
  box-shadow: none !important;
  border-color: none !important;
}
.rbt-input-multi.form-control[disabled] {
  background-color: transparent;
}

.rbt-token {
  background-color: $color-menu-text-active;
  color: $color-white;
  border-radius: 100px;
  padding: 0.5rem;
  margin-right: 0.5rem;
  font-weight: bold;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: $color-menu-text-active;
  }

  .rbt-token-remove-button {
    bottom: 0;
    background-color: transparent;
    border: none;
    color: inherit;
    font-size: inherit;
    font-weight: normal;
    opacity: 1;
    outline: none;
    padding: 3px 7px;
    position: relative;
    right: 0;
    left: 1px;
    text-shadow: none;
    top: -1px;
    height: 3px;
  }
}
.rbt-token-removeable {
  cursor: pointer;
}

.has-aux .form-control {
  color: $color-menu-text-active;
  font-weight: $font-weight-bold;
}

.typeahead-link {
  background-color: transparent;
  color: #8ca608;
  opacity: 1;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid $color-form-border-disabled;
  padding: 1.5rem 2rem;
  display: inline-block;
  text-align: left;
  white-space: nowrap;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  line-height: initial;
  transition: all 0.15s ease-out;
  border-radius: 0.5em;
  height: 5.5rem;

  &--no-border {
    border: none; // this removes the border
  }
}

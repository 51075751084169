label {
  display: block;
  padding-bottom: 0.25rem;
}

.c-toggle {
  position: relative;
  // min-width: 9rem;
  width: 9rem;
  height: 4rem;
  border-radius: 50px;
  border: 0;
  background-color: $color-toggle-bg;
  color: $color-toggle-handle-active;
  overflow: hidden;
  cursor: pointer;
  font-family: "Roboto", "Merriweather", sans-serif;
  font-weight: 800;

  .c-toggle__handle {
    position: absolute;
    width: 3.25rem;
    height: 3.25rem;
    top: 0.4rem;
    left: 0.5rem;
    font-size: 15px;
    font-weight: bold;
    line-height: 1;
    transition: 0.2s ease all;
    background-color: $color-toggle-handle;
    border-radius: 50px;
    z-index: 2;
  }

  &__label-active,
  &__label-inactive {
    position: absolute;
    top: 0.5rem;
  }

  &__label-active {
    left: 0.75rem;
    font-size: 2rem;
  }

  &__label-inactive {
    right: 0.75rem;
    color: $color-toggle-handle;
  }

  &--disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &--active {
    background-color: $color-toggle-bg-active;
    .c-toggle__handle {
      left: 5.3rem;
      right: auto;
      background-color: $color-toggle-handle-active;
    }
  }
}

.c-toggle-mini {
  position: relative;
  width: 6.375rem;
  height: 4rem;
  border-radius: 50px;
  border: 0;
  background-color: $color-toggle-bg;
  color: $color-toggle-handle-active;
  overflow: hidden;
  cursor: pointer;
  font-family: "Roboto", "Merriweather", sans-serif;
  font-weight: 800;

  .c-toggle-mini__handle {
    position: absolute;
    width: 3.25rem;
    height: 3.25rem;
    top: 0.4rem;
    left: 0.5rem;
    font-size: 15px;
    font-weight: bold;
    line-height: 1;
    transition: 0.2s ease all;
    background-color: $color-toggle-handle;
    border-radius: 50px;
    z-index: 2;
  }

  &__label-active,
  &__label-inactive {
    position: absolute;
    top: 0.5rem;
  }

  &__label-active {
    left: 0.75rem;
  }

  &__label-inactive {
    right: 0.75rem;
    color: $color-toggle-handle;
  }

  &--disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &--active {
    background-color: $color-toggle-bg-active;
    .c-toggle-mini__handle {
      left: 2.65rem;
      right: auto;
      background-color: $color-toggle-handle-active;
    }
  }
}

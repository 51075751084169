/*------------------------------------*\
    #HEADINGS
\*------------------------------------*/

h1,
h2,
h3,
h4 {
  font-family: $font-family-primary;
  font-weight: 400;
  margin: $spacing-med 0;
}

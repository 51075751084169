a.c-btn {
  display: flex;
}
a.c-btn-full {
  display: flex;
  width: 100%;
}
.c-btn {
  display: inline-block;
  border: $border-thickness solid $color-btn-primary-border;
  background: $color-btn-primary-bg;
  transition: all 0.4s ease;
  color: $color-btn-primary;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  min-width: 14.25rem;
  font-weight: bold;
  text-transform: capitalize;
  text-align: center;
  transition: all $anim-fade-quick $anim-ease;
  border-radius: 1rem;
  white-space: normal;
  cursor: pointer;

  &__login {
    @extend .c-btn;
    width: 35rem;
  }

  &__edit {
    @extend .c-btn;
    min-width: 10.5rem;
  }

  &__label {
    line-height: normal;
  }

  &__done {
    @extend .c-btn-alt;
    min-width: 10.5rem;
  }

  &__inner {
    display: flex;
    font-size: $font-size-med;
    align-items: center;
    justify-content: space-evenly;
    padding-right: 1rem;
    padding-left: 1rem;

    &--mid-right {
      justify-content: space-between;
      span {
        width: 100%;
        text-align: center;
      }
    }
  }

  &__underlined {
    display: flex;
    font-size: $font-size-med;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
  }

  &__icon {
    height: 2.5rem;
    fill: $color-btn-primary;
    transition: fill $anim-fade-quick $anim-ease;
  }

  &:hover,
  &:focus {
    transition: all 0.4s ease;
    color: $color-btn-primary;
    background-color: $color-btn-primary-bg-hover;
    border-color: $color-btn-primary-bg-hover;
  }

  &:disabled,
  &[disabled] {
    background-color: $color-btn-primary-disabled;
    cursor: not-allowed;
    opacity: 0.4;
    border-color: $color-btn-primary-disabled;
  }
}

.c-btn-tab {
  @extend .c-btn;
  min-width: 5.8rem;
  text-align: center;

  &__skinny {
    @extend .c-btn-tab;
    height: 2rem;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.c-btn-wide {
  display: inline-block;
  border: $border-thickness solid $color-btn-primary-border;
  background: $color-btn-primary-bg;
  transition: all 0.4s ease;
  color: $color-btn-primary;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  width: 42.25rem;
  font-weight: bold;
  text-transform: capitalize;
  text-align: center;
  transition: all $anim-fade-quick $anim-ease;
  border-radius: $border-radius;
  white-space: normal;
  cursor: pointer;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    height: 1.75rem;
    fill: $color-btn-primary;
    transition: fill $anim-fade-quick $anim-ease;
    padding-right: 1rem;
  }

  &:hover,
  &:focus {
    transition: all 0.4s ease;
    color: $color-btn-primary;
    background-color: $color-btn-primary-bg-hover;
    border-color: $color-btn-primary-bg-hover;
  }

  &:disabled,
  &[disabled] {
    background-color: $color-btn-primary-disabled;
    cursor: not-allowed;
    opacity: 0.3;
    border-color: $color-btn-primary-disabled;
  }
}

.c-btn-icon-bare {
  border-color: transparent;
  color: $color-btn-primary-bg;
  background: transparent;
  margin: 1rem;
  font-size: 2.5rem;
  &:hover,
  &:focus,
  &__active {
    cursor: pointer;
    color: $color-btn-primary-bg-hover;
    border-color: transparent;
    background: transparent;
  }
}

.c-btn-alt {
  @extend .c-btn;
  color: $color-btn-secondary;
  border-color: $color-btn-secondary-border;
  background: $color-btn-secondary-bg;

  .c-btn-icon__chevron-alt {
    display: flex;
    font-size: 2.5rem;
    align-items: center;
    justify-content: space-evenly;
    padding-right: 1.5rem;
  }

  &:hover,
  &:focus {
    color: $color-btn-secondary-hover;
    background-color: $color-btn-secondary-bg;
    border-color: $color-btn-secondary-border;
  }
}

.c-btn-alt-wide {
  @extend .c-btn-wide;
  color: $color-btn-secondary;
  border-color: $color-btn-secondary-border;
  background: $color-btn-secondary-bg;

  &:hover,
  &:focus {
    background-color: $color-btn-secondary-bg-hover;
  }
}

.c-btn-icon {
  display: inline-block;
  border: $border-thickness solid $color-btn-primary-border;
  background: $color-btn-primary-bg;
  transition: all 0.4s ease;
  color: $color-btn-primary;
  font-weight: bold;
  text-transform: capitalize;
  text-align: center;
  transition: all $anim-fade-quick $anim-ease;
  border-radius: $border-radius;
  white-space: normal;
  cursor: pointer;
  width: 5.5rem;
  height: 5.5rem;

  &__inner {
    display: flex;
    font-size: 2.5rem;
    align-items: center;
    justify-content: center;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  &__chevron {
    display: flex;
    font-size: 2.5rem;
    align-items: center;
    justify-content: space-evenly;
    padding-right: 1.5rem;
  }

  &__right {
    display: flex;
    font-size: 2.5rem;
    align-items: center;
  }

  &__text {
    font-size: $font-size-med;
    padding-left: 1rem;
  }

  &:hover {
    background-color: $color-btn-primary-bg-hover;
    border-color: $color-btn-primary-bg-hover;
  }
  &:active {
    background-color: $color-btn-primary-bg-hover;
    border-color: $color-btn-primary-bg-hover;
  }
}

.c-btn-icon-alt {
  @extend .c-btn-icon;
  background-color: $color-btn-primary;
  border-color: transparent;
  color: $color-btn-primary-bg;

  &:hover {
    background-color: $color-btn-primary-bg;
    border-color: $color-btn-primary-bg;
    color: $color-btn-primary;
  }
}

.c-btn-icon-static {
  @extend .c-btn-icon;
  background-color: $color-btn-primary;
  border-color: transparent;
  color: $color-btn-primary-bg;

  &:hover {
    background-color: $color-btn-primary;
    border-color: transparent;
    color: $color-btn-primary-bg;
    cursor: default;
  }
}

.c-btn-icon-mini {
  @extend .c-btn-icon;
  width: 4rem;
  height: 4rem;

  &__inner {
    font-size: 2.5rem;
  }
}

.c-btn-idscan {
  @extend .c-btn-icon;
  width: 30.375rem;
  height: 19.125rem;

  &__icon {
    display: flex;
    font-size: 4rem;
    align-items: center;
    justify-content: center;
    padding-bottom: 1.5rem;
  }

  &__inner {
    display: flex;
    font-size: $font-size-med;
    align-items: center;
    justify-content: center;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  &__corner-top {
    display: flex;
    font-size: $font-size-med;
    justify-content: space-between;
    padding: 0 0.4rem;
    transform: translate(0, -2.5rem);
    .c-btn-left {
      transform: rotate(-45deg);
    }
    .c-btn-right {
      transform: rotate(45deg);
    }
  }
  &__corner-bottom {
    display: flex;
    font-size: $font-size-med;
    justify-content: space-between;
    padding: 0 0.4rem;
    transform: translate(0, 2.5rem);
    .c-btn-left {
      transform: rotate(45deg);
    }
    .c-btn-right {
      transform: rotate(-45deg);
    }
  }
}

.c-btn-bare {
  @extend .c-btn;
  border-color: transparent;
  color: $color-btn-primary-bg;
  background: transparent;
  &:hover,
  &:focus,
  &__active {
    cursor: pointer;
    color: $color-btn-primary-bg-hover;
    border-color: transparent;
    background: transparent;
  }
}

.c-btn-bare-tab {
  @extend .c-btn-bare;
  min-width: 5.8rem;
}

.c-btn-filter {
  @extend .c-btn;
  border: $border-thin solid $color-btn-primary-border;
  border-radius: $border-radius-lg;
  padding: 0.75rem;
  min-height: 4rem;
  height: 4rem;
  min-width: 5.75rem;
  border-color: $color-btn-filter-border;
  color: $color-btn-filter-text;
  background: $color-btn-outline-bg;
  font-weight: initial;
  transition: all $anim-fade-quick $anim-ease;
  cursor: pointer;

  .c-btn__inner {
    padding-right: 3rem;
    padding-left: 0;
    position: relative;
  }

  .c-btn__icon {
    position: absolute;
    font-size: 2.5rem;
    top: 0rem;
    right: 0rem;
    padding: 0 0 0 1rem;
  }

  &:hover,
  &:focus {
    background-color: $color-btn-outline-bg-hover;
    color: $color-btn-primary;
    border-color: $color-btn-filter-border;
  }
}
.c-btn-profile {
  background-color: $color-btn-primary;
  color: $color-btn-secondary;
  border-radius: $border-radius-lg;
  border: $color-btn-secondary-bg;
  height: 6rem;
  width: 6rem;
}
.c-btn-dd-profile {
  margin: 10rem 2rem 2rem 2rem;
  background-color: $color-btn-primary;
  color: $color-btn-secondary;
  border-radius: $border-radius-lg;
  border: $color-btn-secondary-bg;
  height: 6rem;
  width: 6rem;
}

.c-status-led {
  display: flex;
  align-items: center;

  &__label {
    text-transform: uppercase;
    font-size: $font-size-sm;

    &--active {
      color: $color-status-led-active;
    }

    &--unknown {
      color: $color-status-led-unknown;
    }

    &--inactive {
      color: $color-status-led-inactive;
    }

    &--warning {
      color: $color-status-led-warning;
    }

    &--error {
      color: $color-status-led-error;
    }
  }
  &__light {
    margin-right: 0.5rem;
    &--active {
      fill: $color-status-led-active;
    }

    &--unknown {
      border: 2px solid;
      border-radius: 100px;
      padding: 0.8rem;
    }

    &--inactive {
      fill: $color-status-led-inactive;
    }

    &--warning {
      color: $color-status-led-warning;
    }

    &--error {
      color: $color-status-led-error;
    }
  }
  svg {
    width: 5rem;
    height: 5rem;
  }
}

.barchart {
  font-family: sans-serif;
}

path {
  fill: none;
}
svg {
  min-height: 5rem;
  height: 10rem;
  width: 100%;
  //fill: transparent;
  //stroke: transparent;
  g {
    color: transparent;
  }
  text {
    color: #556066;
  }
}

.tick line {
  opacity: .2;
}
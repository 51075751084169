.c-tabset {
  display: inline-grid;
  // grid-template-columns: repeat(auto-fit, minmax(93px, 1fr));
  grid-auto-flow: column;
  overflow: auto;
  grid-gap: 1rem;
  padding: 0.75rem;
  background: $color-tabset-bg;
  color: $color-tabset-text;
  text-align: center;
  font-weight: bold;
  font-family: $font-family-primary;
  border-radius: 1.5rem;
  margin-bottom: 3rem;
  min-height: 7rem;

  .c-tab__item {
    white-space: nowrap;
    border-radius: 1rem;
    padding: 0.75rem;
    cursor: pointer;
    width: 11.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;

    &--active {
      background: $color-tabset-bg-active;
      color: $color-tabset-text-active;
      border-radius: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
    }
  }
}

.c-tabset-vertical {
  @extend .c-tabset;
  display: block;
  margin-bottom: 0;
}

.c-tabset-bare {
  @extend .c-tabset;
  background: transparent;
  margin: 3rem;

  .c-tab__item {
    height: 4rem;
    width: 9rem;
    margin: 0 0.5rem;
    &--active {
      height: 4rem;
      width: 9rem;
    }
  }
}

.c-tabset-skinny {
  display: inline-grid;
  grid-template-columns: repeat(auto-fit, minmax(93px, 1fr));
  grid-auto-flow: column;
  overflow: auto;
  grid-gap: 1rem;
  padding: 0.75rem;
  background: $color-tabset-bg;
  color: $color-tabset-text;
  text-align: center;
  font-weight: bold;
  font-family: $font-family-primary;
  border-radius: 1.5rem;
  margin-bottom: 4rem;
  height: 5.5rem;

  .c-tab__item {
    white-space: nowrap;
    border-radius: 1rem;
    cursor: pointer;
    min-width: 11.625rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &--active {
      background: $color-tabset-bg-active;
      color: $color-tabset-text-active;
      border-radius: 1rem;
      height: 4rem;
    }
  }
}

.c-linkset {
  display: inline-grid;
  grid-template-columns: repeat(auto-fit, minmax(93px, 1fr));
  grid-auto-flow: column;
  overflow: auto;
  background: $color-tabset-bg;
  color: $color-tabset-text;
  text-align: center;
  font-weight: bold;
  font-family: $font-family-primary;
  border-radius: 1rem;
  text-align: center;
  height: 5.5rem;

  .c-tab__item {
    white-space: nowrap;
    border-radius: 1rem;
    padding: 1.15rem 3.5rem;
    cursor: pointer;
    min-width: 12.5rem;

    &--active {
      background: $color-tabset-bg-active;
      color: $color-tabset-text-active;
      border-radius: 0;
    }
  }
}

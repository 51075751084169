.c-primary-nav {
  &__list {
    list-style: none;
    display: flex;

    @media all and (max-width: $bp-xl) {
      display: none;
    }
  }

  &__inner {
    padding: 0 2rem;
  }

  &__dropdown {
    list-style: none;
    display: flex;
    flex-direction: column;
  }

  &__item {
    margin: 0 1rem;
    text-transform: capitalize;
    display: flex;
    align-items: center;

    > .c-primary-nav__link {
      display: inline-flex;
      &:hover {
        background: $color-btn-primary-bg-hover;
        color: $color-btn-primary;
        align-items: center;
        margin: 0;
      }
      &.is-active {
        border-radius: $border-radius;
        height: 4rem;
        margin: 0;
        align-items: center;
        background: $color-btn-primary-bg;
        color: $color-btn-primary;
      }
    }
  }

  &__link {
    color: $color-btn-primary;
    &:hover {
      border-radius: $border-radius;
      height: 4rem;
      margin: 0;
    }
  }
}

.c-mobile-nav {
  margin: 1rem 2rem;

  @media all and (min-width: $bp-xl) {
    display: none;
  }
}

.c-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  &__image {
    width: 150px;
    max-width: 40rem;
    background: transparent;

  }
}

.c-incrementer {
display: flex;
}

.c-incrementer-input {
    display: flex;
    align-items: center;
    width: 10rem;
    height: 5.5rem;
    color: $color-btn-filter-text;
    border: $border-thin solid $color-btn-primary-border;
    border-radius: $border-radius;
    background: $color-btn-primary;
    margin: 0 1.25rem;
    padding: 0 2rem;
}
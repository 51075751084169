.checkbox-custom {
  opacity: 0;
  position: absolute;
}

.checkbox-custom,
.checkbox-custom-label {
  display: inline-block;
  vertical-align: middle;
  margin: 5px;
  cursor: pointer;
}

.checkbox-custom-label {
  position: relative;
}

.checkbox-custom + .checkbox-custom-label:before {
  content: "";
  background: $color-checkbox-text-active;
  border: 2px solid $color-checkbox-text;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 4px;
  text-align: center;
  transition-duration: 0.3s;
}

.checkbox-custom:checked + .checkbox-custom-label:before {
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  font-size: 18px;
  background: $color-checkbox-bg;
  border: 2px solid $color-checkbox-border-active;
  border-radius: 4px;
  vertical-align: middle;
  color: $color-checkbox-text-active;
  transition-duration: 0.3s;
  line-height: 1.4;
}

.minusbox {
  display: inline-flex;
  cursor: pointer;
  position: relative;
}

.minusbox > span {
  color: $color-checkbox-bg;
}

.minusbox > input {
  height: 25px;
  width: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 2px solid $color-checkbox-text;
  border-radius: 4px;
  outline: none;
  transition-duration: 0.3s;
  background-color: $color-checkbox-text-active;
  cursor: pointer;
}

.minusbox > input:checked {
  border: 1px solid $color-checkbox-bg;
  background-color: $color-checkbox-bg;
}

.minusbox > input:checked + span::before {
  content: "\2015";
  font-size: 22px;
  display: block;
  text-align: center;
  color: $color-checkbox-text-active;
  position: absolute;
  left: 0.7rem;
  top: -0.4rem;
}

.minusbox > input:active {
  border: 2px solid $color-checkbox-bg;
}

.c-radio-button {
  display: inline-flex;
  cursor: pointer;
  position: relative;
}

.c-radio-button > span {
  color: $color-checkbox-text;
}

.c-radio-button > input {
  height: 3rem;
  width: 3rem !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 2px solid $color-checkbox-text;
  border-radius: 100px;
  outline: none;
  transition-duration: 0.3s;
  cursor: pointer;
  padding: 0;
  margin-right: 1rem;
}

.c-radio-button > input:checked {
  border: 2px solid $color-checkbox-bg;
  background-color: $color-checkbox-bg;
}

.c-radio-button > input:checked::before {
  content: "";
  width: 1rem;
  height: 1rem;
  border-radius: 50px;
  display: block;
  text-align: center;
  color: $color-checkbox-text-active;
  position: absolute;
  left: 1rem;
  top: 1rem;
  background: $color-checkbox-text-active;
}

.c-radio-button > input:active {
  border: 2px solid $color-checkbox-bg;
}

input:disabled + label {
  opacity: 0.3;
  cursor: not-allowed;
}

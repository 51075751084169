.Toastify__toast--success {
    background: $color-alert-success !important;
}

.Toastify__toast--error {
    background: $color-alert-error !important;
}

.Toastify__toast--warning {
    background: $color-alert-warning !important;
}

.Toastify__toast--default {
    background: $color-alert-default !important;
    color: $color-white !important;
}
  
.Toastify__toast-container--top-right.wide-toast {
    width: 400px !important;
}

.Toastify__close-button {
    align-self: center !important;

    svg {
        width: 20px !important;
        height: 20px !important;
    }
}
